<template>
  <header>
    <div class="usa-banner" 
>
      <div class="usa-banner__header minh-0">
        <div class="usa-banner__inner">
          <img
            class="usa-banner__header-flag"
            width="16"
            height="11"
            src="/assets/img/us_flag_small.png"
            alt="U.S. Flag"
          />
          <p  class="usa-banner__header-text">
            Official U.S. Department of State Visa Appointment Service
          </p>
        </div>
      </div>
    </div>

    <div 
      class="usa-overlay"
      v-on:click="showMobileMenu = !showMobileMenu"
      v-bind:class="{ 'is-visible': showMobileMenu }"
    ></div>
    <!--div v-if = "!this.$route.name.includes('depositslip') && !this.$route.name.includes('landing')" class="usa-header usa-header--extended"-->
              <!-- Shubham, 8-feb-2024, Resolve the parsing error were this.route was going null. To manage that made a function to check the route's availability -->
    <div v-if = "isRouteNameAvailable && !this.$route.name.includes('depositslip') && !this.$route.name.includes('landing')" class="usa-header usa-header--extended">
        
      <div class="usa-navbar">
        <div v-show="this.$route.name!==''" class="usa-logo" id="extended-logo">
          <em class="usa-logo__text">
            <a href="nonimmigrant-visa?nodialog" aria-label="U.S. Travel Docs"
              ><span class="text-primary">US</span
              ><span class="text-primary-light">Travel</span
              ><span class="text-primary">Docs</span></a
            >
            <span v-if="country !== '' && !isDecommission"><a class="cgi-badge radius-pill tablet:font-sans-md" href="" :aria-label="country">{{ country }}</a></span>
          </em>
        </div>
        <button
          type="button"
          class="usa-menu-btn"
          v-on:click="showMobileMenu = !showMobileMenu"
        >
          <svg width="32" height="32" viewBox="0 0 32 32" :class="homeonly" role="img" focusable="false" aria-labelledby="svgMenuTitle" aria-describedby="svgMenuDescription">
          <title id="svgMenuTitle">Menu</title>
          <desc id="svgMenuDescription">Menu</desc>
            <path
              d="M4 10h24a2 2 0 0 0 0-4H4a2 2 0 0 0 0 4zm24 4H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4zm0 8H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4z"
            /></svg>
          {{translationKeywords['menu'] || 'Menu'}}
        </button>
      </div>
      <nav
        class="usa-nav"
        aria-label="Primay header with 7 items"
        v-bind:class="{ 'is-visible': showMobileMenu }"
      >
        <div class="usa-nav__inner">
          <button
            type="button"
            class="usa-nav__close"
            v-on:click="showMobileMenu = !showMobileMenu"
          >
            <img src="/assets/img/close.svg" alt="close" />
          </button>
          <ul class="usa-nav__primary usa-accordion" :class="showSubMenu">
            <li class="usa-nav__primary-item">
              <a class="usa-nav__link" href="nonimmigrant-visa?nodialog"
                ><span>{{translationKeywords['home'] || 'Home'}}</span></a
              >
            </li>
            <li :key="key" v-for="(menu, key) of data.fields.menuItemReferences">
              <document-renderer :documentId="menu.sys.id"></document-renderer>
            </li>
          </ul>
          
          <div v-if="country !==''"  class="grid-row usa-nav__secondary" :class="singleHead">
            <div class="grid-col-12 desktop:grid-col-8">
              <ul class="usa-nav__primary usa-accordion" role="navigation">
                <li class="usa-nav__primary-item">
                  <a href="contact-us">{{translationKeywords['contact'] || 'Contact'}}</a>
                </li>
                <li class="usa-nav__primary-item">
                  <a href="general-information#faqs">{{translationKeywords['faqs'] || 'Faqs'}}</a>
                </li>
                <li class="usa-nav__primary-item">
                  <button
                    type="button"
                    class="usa-accordion__button usa-nav__link"
                    :aria-expanded="toggleMenu ? 'true' : 'false'"
                    aria-controls="change-language"
                    @click="toggleMenu = !toggleMenu" 
                    v-click-outside="outside"
                  >      
                    <span>{{translationKeywords['change-language'] || 'Change language'}}</span>

                    <span class="mobile-only">
                      <span class="icon" v-if="toggleMenu">
                        <img
                          src="/assets/img/minus-alt.svg"
                          alt="collaps"
                          width="12"
                          height="15"
                        />
                      </span>
                      <span class="icon" v-else-if="!toggleMenu">
                        <img
                          src="/assets/img/plus-alt.svg"
                          alt="expand"
                          width="12"
                          height="15"
                        />
                      </span>
                    </span>
                  </button>
                  <ul
                    class="usa-nav__submenu desktop:border desktop:border-base-lighter desktop:shadow-5 none"
                    id="change-language"
                    :class="{ block: toggleMenu }"
                    v-if="languages"
                  >
                    <li
                      :key="i"
                      v-for="(lang, i) of languages"
                      class="usa-nav__submenu-item"
                    >
                      <a :href="langUrl(countryCode, lang)">{{ languageLabels[lang] }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-show="$route.name!=='landing-page'" class="grid-col-12 desktop:grid-col-4">
              <form
                class="usa-search usa-search--small"
                action="http://www.bing.com/search"
              >
                <input type="hidden" name="cp" value="utf-8" />
                <input type="hidden" name="q1" value="site:www.ustraveldocs.com/in" />
                <div role="search">
                  <label class="usa-sr-only" for="site-search">{{translationKeywords['search-site'] || 'Search site'}}</label>
                  <input class="usa-input" id="site-search" type="search" name="q" />
                  <button type="submit" class="usa-button">
                    <span class="usa-sr-only">Search site</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <a
            v-if = "scheduleurl1 != ''"
             :class="showSubMenu"
             :href="scheduleurl1"
              id="visa-login"
             class="cgi-schedule usa-button usa-button--green position-absolute top-0 right-0 radius-0"
           >
             <span class="cgi-schedule__icon">
                 <svg width="24" height="24" viewBox="0 0 448 512" :class="homeonly" role="img" focusable="false" aria-labelledby="svgCalenderTitle" aria-describedby="svgCalenderDescription">
                   <title id="svgCalenderTitle">Calender</title>
                   <desc id="svgCalenderDescription">Calender</desc>
                 <path
                   d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                 /></svg>
            </span>
             <span class="cgi-schedule__text">{{translationKeywords['acs-login'] || 'ACS'}}</span>
           </a>
           <a
             :class="showSubMenu"
             :href="scheduleurl"
              id="acs-login"
             class="cgi-schedule2 cgi-schedule usa-button usa-button--green position-absolute top-0 right-0 radius-0"
           >
             <span class="cgi-schedule__icon">
                 <svg width="24" height="24" viewBox="0 0 448 512" :class="homeonly" role="img" focusable="false" aria-labelledby="svgCalenderTitle" aria-describedby="svgCalenderDescription">
                   <title id="svgCalenderTitle">Calender</title>
                   <desc id="svgCalenderDescription">Calender</desc>
                 <path
                   d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                 /></svg>
            </span>
             <span class="cgi-schedule__text">{{translationKeywords['visa-login'] || 'Visa Login'}}</span>
           </a> 
         
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { urlSlug,urlLangCode,urlCountryCode } from "@/helpers/url";
import {
  getLanguageUrlLabels,
  getLanguages,
  isRedirectToHomeOnLangChange,
  getTranslationKeywords,
  getDataByForTranslation,
  getCountryName,
  getLanguageLabels,
  //pooja, 10-july-2023, login url for country
  getCountryLoginUrl,
  getCountryAcsLoginUrl,
  getCountryResourceGroup
} from "@/store/api";

export default {
  name: "header-component",
  props: ["data"],
  data: () => ({
    toggleMenu: false,
    languages: null,
    languageLabels: '',
    languageUrlLabels: '',
    countryCode: null,
    showMobileMenu: false,
    languageChangeRedirect: false,
    translationKeywords:{},
    pageData: {},
    country: '',
    scheduleurl: '',
    scheduleurl1: '',
    fullLanguage:'',
    acsflag: false,
    isDecommission: false
  }),
  computed: {
    showSubMenu() {
      var urlSlugText = urlSlug();
      if (urlSlugText == "") return "none";
      else return "";
    },
    singleHead() {
      var urlSlugText = urlSlug();
      if (urlSlugText == "") return "single-head";
      else return "";
    },
    // Shubham, 8-feb-2024, Added function to check the route's availability
    isRouteNameAvailable(){
      if(this.$route && this.$route.name) return true;
      else return false;
    }
  },
  methods: {
    langUrl(countryCode, lang) {
      const url = new URL(location.href);
      const pathParts = url.pathname.split("/").filter((v) => v);
      if (this.languageChangeRedirect) {
        url.pathname = `/${pathParts[0]}/${lang}`;
        url.hash = '';
      } else {
        pathParts[1] = lang;
        url.pathname = pathParts.join("/");
      }
      return url.toString();
    },
    outside: function(e) {
      this.toggleMenu = false;
    },
  },
  created() {
    getDataByForTranslation("buttonCta", "schedule-appt", this.$route.params.countrycode, this.$route.params.langcode).then((pageData) => {
     
      this.dataLoaded = true;
      if (!pageData) {
        console.log('404');
      }
      this.pageData = pageData;
    });
    this.countryCode = urlCountryCode();
    Promise.all([
      getLanguages(this.countryCode),
      getLanguageUrlLabels(),
      getLanguageLabels(),
      isRedirectToHomeOnLangChange(this.countryCode),
    ]).then(([languages, languageUrlLabels, languageLabels, languageChangeRedirect]) => {
      this.languageChangeRedirect = languageChangeRedirect;
      this.languages = languages;
      this.languageLabels = languageLabels;
      getCountryName(this.countryCode).then((country) => {
        this.country = country;
        const fullLanguage = languageUrlLabels[urlLangCode()];
        //pooja, 10-july-2023, login url for country
        // getCountryLoginUrl(this.countryCode, this.$route.params.langcode).then((countryLoginUrl) => {
        //   this.countryLoginUrl = countryLoginUrl;
        //   if(countryLoginUrl) {this.scheduleurl = this.countryLoginUrl}
        //   else {
        //     this.acsflag = true;
        //     this.scheduleurl = 'https://cgifederal.secure.force.com/?language='+fullLanguage+'&country='+country;
        //   }
        // });

        getCountryResourceGroup(this.countryCode).then((res)=>{
           const nonLiveCountryJson = res?.fields?.resJson['non-live-countries']?.find((item) => item?.country === this.countryCode);
           console.log("nonLiveCountryJson------------------",nonLiveCountryJson?.decommission);
           if(nonLiveCountryJson?.decommission){
             this.isDecommission = true;
           }else{
             this.isDecommission = false;
           }
          });

        function getCountryLoginPromise(countryCode, langCode) {
          return new Promise((resolve, reject) => {
            getCountryLoginUrl(countryCode, langCode)
              .then((countryLoginUrl) => {
                console.log("============================");
                console.log(countryLoginUrl);
                console.log("============================");

                if (countryLoginUrl) {
                  console.log("usvisascheduling");
                  resolve(countryLoginUrl);
                } else {
                  console.log("cgifederal");
                  reject("No country login URL found."); 
                }
              })
              .catch((error) => {
                reject(error); 
              });
          });
        }

        getCountryLoginPromise(this.countryCode, this.$route.params.langcode)
        .then((url) => {
          this.scheduleurl = url;

        })
        .catch((error) => {
          console.error(error); 
          this.acsflag = true;
        });


        getCountryAcsLoginUrl(this.countryCode, this.$route.params.langcode).then((countryLoginUrl) => {
          this.countryLoginUrl = countryLoginUrl;
          if(countryLoginUrl) {this.scheduleurl1 = this.countryLoginUrl}
          else{
            document.getElementById("acs-login").className = document.getElementById("acs-login").className.replace( /cgi-schedule2/g , 'cgi-schedule' )
          }
        });
      });
    });
    
  },
  directives: {
    'click-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }
          
          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
      },
      
      unbind: function(el, binding) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (console.log(this.translationKeywords=keywords))
    );
  }
};
</script>

<style lang="scss" scoped>
.usa-megamenu {
  display: none;
}
.show-non {
  display: block;
}

.none {
  display: none;
}
.single-head {
    bottom: 2rem !important;
}
.index-page {
 .single-head {
    bottom: 1.2rem !important;
} 
}
.block {
  display: block;
}
.mobile-only {
  display: none;
}

::v-deep .green-button a {
  font-family: 'Helvetica',Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,sans-serif;
  font-size: 16px;
  line-height: 0.93923;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  padding: 1rem 1.5rem;
  text-align: center;
  text-decoration: none;
  width: auto;
  span {
    display: none;
  }
  &:hover {
    color: #FFF;
    span {
      display: none;
    }
  }
}
::v-deep .green-button a:before {
  content: url("/assets/img/calender.svg");
  left: 17px;
  position: absolute;
}
@media (min-width: 320px) and (max-width: 767px) {
  .usa-nav__primary {
    button {
      background: none;
    }
  }
  .mobile-only {
    display: block;
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .usa-nav__primary {
    button {
      background: none;
    }
  }
  .mobile-only {
    display: block;
    float: right;
  }
}

@media (min-width: 64em) {
  .usa-header--extended .usa-logo {
    font-size: 2rem;
  }
  .usa-nav__primary {
    align-items: center;
  }
  .usa-nav__primary > .usa-nav__primary-item {
    max-width: 160px;
  }
}
.usa-logo a.radius-pill {
  font-size: 13px;
  font-weight: 600;
}
@media screen and (min-width: 1024px) {


  .rtl-global  .cgi-schedule2{
    left: 12.5rem;
    right:initial;
  }
  .cgi-schedule2{
     right: 14rem;
     bottom: 0px;
  }
}
</style>