<template>
  <li
    class="grid-col-12 margin-top-2 tablet:margin-top-0"
    :class="[data.fields.styleInstructions, getMediumWidth]"
  >
    <div class="cgi-card padding-x-3 shadow-2 text-bold" :class="getSmallWidth">
      <rich-text-renderer :data="data.fields.cardBody"></rich-text-renderer>
    </div>
  </li>
</template>
<script>
export default {
  name: "single-block",
  props: ["data"],
  computed: {
    getMediumWidth() {
      return this.data.fields.styleInstructions === "medium-width image-card"
        ? "tablet:grid-col-6"
        : "tablet:grid-col-4";
    },
    getSmallWidth() {
      return this.data.fields.styleInstructions === "small-card"
        ? " padding-y-2 "
        : " padding-y-5 ";
    }
    
  },
};
</script>

<style lang="scss" scoped>
li {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  // margin-top: 30px;
}
.yt-video-card {
  margin: 0;
  padding: 0;
  height: 167px;
  width: 300px;
  .cgi-card {
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: 2px solid #FFF;
    ::v-deep iframe {
      width: 100%;
    }
  }
}
.no-shadow {
  .shadow-2 {
    box-shadow: none;
  }
}
.left-align {
  text-align: left;
}
.card-with-icon {
  margin-bottom: 30px;
  margin-top: 5px;
  ::v-deep .usa-link {
    text-decoration: none;
    &:after {
      position: absolute;
      background: url('/assets/img/angle-arrow-right-blue.svg') no-repeat;
      width: 9px;
      height: 19px;
      margin-left: 0.5em;
      content: "";
      background-size: 14px 24px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.image-card {
  margin-top: 0;
  .cgi-card {
    box-shadow: none;
    padding: 0;
    border: none;
  }
}
.step-6 {
  #track-passport-phone-1,
  #track-passport-phone-2 {
    ::v-deep .cgi-card {
      text-align: left;
      p:last-child {
        font-weight: 400;
      }
    }
  }
  #track-passport-phone-US {
    ::v-deep .cgi-card {
      text-align: left;
      p {
        font-weight: 400;
      }
    }
  }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 767px) {
  .step-6 {
    #track-passport-phone-1 {
      margin-bottom: 15px;
    }
  }
  .yt-video-card {
    width: 100%;
  }
  .step-3 {
    .fee-payment-card {
      li {
        margin-bottom: 15px;
      }
    }
  }
  .contact-us{
    #contact-us-call-1{
      margin-bottom: 15px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .step-6 {
    #track-passport-phone-1 {
      margin-bottom: 15px;
    }
  }
  .yt-video-card {
    width: 50%;
  }
  .step-3 {
    .fee-payment-card {
      li {
        margin-bottom: 15px;
      }
    }
  }
  .contact-us{
    #contact-us-call-1{
      margin-bottom: 15px;
    }
  }
}

.rtl {
  .card-with-icon {
    ::v-deep .usa-link {
      &:after {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
            margin-right: 6px;
      }
    }
  }
}
</style>
