<template>
  <div v-if = "!this.$route.name.includes('depositslip')" class="footer-links-holder" v-bind:class="{ active: footerToggle }">
    <h1 class="footer-heading" @click="toggleFooter()">Immigrant visas</h1>
    <rich-text-renderer
      :data="data.fields.details"
      class="footer-links"
    ></rich-text-renderer>
  </div>
</template>

<script>
export default {
  name: "footer-right",
  props: ["data"],
  data() {
    return {
      footerToggle: false,
    };
  },
  created() {
    
    this.$root.$on('buttonCta:loaded', (si) => {
      if(si == 'transparent-body-white-border top-margin-extra'){
        this.$nextTick(() => {
          const itm = this.$el.querySelector(".transparent-body-white-border");
          const clone = itm.cloneNode(true);
          clone.classList.add("respButton");
          clone.classList.remove("transparent-body-white-border");
          document.querySelector(".footer-navigation").append(clone);
        });
      }
    });

    this.$root.$on("footer:expand", (id) => {
      if (id !== 3) {
        this.footerToggle = false;
        return;
      }
      this.footerToggle = !this.footerToggle;
    });
  },
  methods: {
    toggleFooter(e) {
      this.$root.$emit("footer:expand", 3);
    },
  },
};
</script>

<style lang="scss" scoped></style>
