<template>
  <div class="basic-component">
    <div class="container" v-if="data">
      <h1>THIS IS BASIC COMPONENT</h1>
      <h1>{{ data.fields.title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "basic-component",
  props: ["data"],
  created(){
    console.info("Basic component data => ", this.data);
  }
};
</script>

<style scoped>
.basic-component {
  display: inline-block;
  border: 1px solid red;
  margin: 10px;
}
</style>
