<template>
    <div class="grid-row tablet:grid-gap-4 colsec">
        <div class="grid-col-12 margin-y-1 tablet:grid-col-6">
            <div class="grid-row grid-gap-2">
                <div v-if="data.fields.column1Icon == 'building'" class="grid-col-auto branch-icon">
                    <svg width="24" height="24" viewBox="0 0 448 512" :class="homeonly" aria-hidden="true" role="presentation" focusable="false" >
                        <path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"></path>
                    </svg>
                </div>
                <div v-if="data.fields.column1Icon == 'map-location-pin'" class="grid-col-auto branch-icon">
                    <svg width="24" height="24" viewBox="0 0 384 512" :class="homeonly" aria-hidden="true" role="presentation" focusable="false" >
                        <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                    </svg>
                </div>
                <div class="grid-col-fill">
                     <rich-text-renderer :class="StyleClass" 
                    :data="data.fields.column1Text"
                    ></rich-text-renderer> 
                </div>
            </div>
        </div>
        <div class="grid-col-12 margin-y-1 tablet:grid-col-6">
            <div class="grid-row grid-gap-2">
                <div v-if="data.fields.column2Icon == 'building'"  class="grid-col-auto  address-icon">
                    <svg width="24" height="24" viewBox="0 0 448 512" aria-hidden="true" role="presentation" focusable="false"><path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"></path></svg>
                </div>
                <div v-if="data.fields.column2Icon == 'map-location-pin'"  class="grid-col-auto  address-icon">
                    <svg width="24" height="24" viewBox="0 0 384 512" aria-hidden="true" role="presentation" focusable="false" ><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                </div>
                <div class="grid-col-fill">
                    <rich-text-renderer :class="StyleClass" 
                    :data="data.fields.column2Text"
                    ></rich-text-renderer>
                    <rich-text-renderer :class="StyleClass" 
                    :data="data.fields.column2MoreLink"
                    ></rich-text-renderer>
                </div>
            </div>
        </div>
    </div>
</template>




<script>

export default {
    name: "column-section",
    props: ["data"],
    data() {
    return {
      StyleClass: "",
      StyleClassCrossed: "",
      StyleClassTicked: ""
    };
  },
};
</script>

<style lang="scss" scoped>
.step-5 {
  .branch-icon svg,
  .address-icon svg {
    height: 20px;
    width: 20px;
  }
}
.svg-sm{
    display: none;
}
.dark-grey-crossed{
    display:block;
}
.dark-grey-ticked{
    display:block;
}
</style>