import Vue from 'vue';
import { componentNameMapping, modalComponentNameMapping } from "@/config/component-registration";

export function getComponent(componentName, debugInfo) {
  const component = modalComponentNameMapping[componentName];
  if (component) {
    return component;
  }

  console.info(
    "DOCUMENT RENDERER: Component not find for => ",
    componentName,
    debugInfo
  );
  return modalComponentNameMapping.BasicComponent;
}

export function registerComponents() {
  Object.keys(componentNameMapping).forEach(key => Vue.component(key, componentNameMapping[key]));
}