<template>
  <li
    class="usa-search__result margin-bottom-3"
    :class="[data.fields.faqSectionSlug, QuestionDisplay]"
  >
    <dl class="faq-dl margin-0">
      <dt class="faq-q padding-1 text-bold">
        <rich-text-renderer :data="data.fields.question"></rich-text-renderer>
      </dt>
      <dd class="faq-a bg-primary-lighter line-height-sans-5 margin-0 padding-2">
        <rich-text-renderer :data="data.fields.answer"></rich-text-renderer>
      </dd>
    </dl>
  </li>
</template>

<script>

export default {
  name: "faq-entry",
  props: ["data", "additionaldata"],
  data() {
    return {
      QuestionDisplay: "displaynone",
    };
  },
  created() {
    this.additionaldata.faqSection.$on("show-question", (questionId) => {
      if (this.data.fields.faqSectionSlug == questionId) {
        this.QuestionDisplay = "displayblock";
        this.additionaldata.faqSection.$emit('question-shown');
      } else {
        this.QuestionDisplay = "displaynone";
      }
    });

    this.additionaldata.faqSection.$on("question-filter", (searchData) => {
      const subject = this.$el.textContent.toLowerCase();

      if(subject.includes(searchData.data.toLowerCase())) {
        this.QuestionDisplay = "displayblock";
        this.additionaldata.faqSection.$emit('question-shown');
      } else {
        this.QuestionDisplay = "displaynone";
      }
    });
  },
};
</script>
<style lang="scss" scoped>
li {
  display: none;
}
.displaynone {
  display: none;
}
.displayblock {
  display: block;
  position: relative;
}
.faq-q {
  ::v-deep p {
    margin: 0;
  }
}
.faq-a {
  ::v-deep p {
    margin: 0;
  }
}
</style>