<template>
    <aside class="usa-layout-docs__sidenav desktop:grid-col-3">
        <nav aria-label="topics">
            <ul class="usa-sidenav usa-list--unstyled">
                <document-renderer :documentId="step.sys.id" :key="i" v-for="(step, i) of data.fields.leftMenuItemReferences"></document-renderer>
            </ul>
        </nav>
    </aside>
</template>

<script>
export default {
  name: "left-side-menu",
  props: ["data"],
  data() {
    return {
      StyleClass: ""
    };
  },
};
</script>
<style lang="scss" scoped>
.usa-sidenav {
  padding: 0;
  .usa-sidenav__item {
    margin: 0;
    &:first-child {
      border-top: 0;
    }
  }
}
</style>