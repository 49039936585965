<template>
  <div>
    <!-- <div class="margin-bottom-1">For information on the visa interview process, see the videos below:</div> -->
    <div :key="j" v-for="(step, j) of data.fields.cardBody.content">
      <div :key="i" v-for="(step2, i) of step.content">
        <div v-html="step2.value"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "small-shaded-card",
  props: ["data"],
};
</script>

