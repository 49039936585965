<template>
  <li class="usa-nav__primary-item" :class="data.fields.styleInstructions">
    <rich-text-renderer :data="data.fields.menuLinks"></rich-text-renderer>
  </li>
</template>

<script>
export default {
  name: "header-menu-column",
  props: ["data"],
  data: () => ({
    toggleMenu: false,
    nonMegaMenu: false,
    ImMegaMenu: false,
  }),
};
</script>

<style lang="scss" scoped>
.usa-megamenu {
  display: none;
}
.show-non {
  display: block;
}

.none {
  display: none;
}
.block {
  display: block;
}

.usa-nav__primary-item {
  width: 33%;
  word-break: break-word;
  ::v-deep {
    .rich-text {
      h3:first-child {
        margin-top: 8px;
      }
    }
    h3 {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.6;
      margin: 18px 0 8px 0;
    }
    p {
      margin: 8px 0;
      font-size: 15px;
    }
  }
  &.left-padded {
    margin-left: 245px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .usa-nav__primary-item {
    width: 100%;
    ::v-deep {
      .rich-text {
        h3 {
          margin-top: 0;
        }
      }
    }
    &.left-padded {
      margin-left: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .usa-nav__primary-item {
    width: 100%;
    ::v-deep {
      .rich-text {
        h3{
          margin-top: 0;
        }
      }
    }
    &.left-padded {
      margin-left: 0;
    }
  }
}
</style>
