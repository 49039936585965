<template>
  <div class="rich-text" v-if="data">
    <RichTextRenderer
      :document="data"
      :nodeRenderers="rendererNodes()"
      :markRenderers="rendererMarks()"
    />
  </div>
</template>

<script>
import { get } from "lodash";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

function blocksEmbeddedEntryRenderer(node, key, handler, next) {
  return handler("document-renderer", {
    key: key,
    documentId: node.data.target.sys.id,
  });
}

function inlineEmbeddedEntryRenderer(node, key, handler, next) {
  return handler("document-renderer", {
    key: key,
    documentId: node.data.target.sys.id,
  });
}

function embeddedAssetRenderer(node, key, handler, next) {
  const type = get(node, "data.target.sys.linkType");
  return handler("document-renderer", {
    key: key,
    documentId: node.data.target.sys.id,
    documentType: type,
  });
}

function linkRenderer(node, key, handler, next) {
  return handler("link-component", {
    key: key,
    url: node.data.uri,
    text: node.content[0].value,
  });
}

function codeRenderer(node, key, handler, next) {
  return handler("html-renderer", {
    key: key,
    html: node,
  });
}

function asssetHyperlinkRenderer(node, key, handler, next) {
  return handler("document-renderer", {
    key: key,
    documentId: node.data.target.sys.id,
    documentType: node.data.target.sys.linkType,
    props: {
      additionaldata: node.content,
    },
  });
}

export default {
  name: "rich-text-renderer",
  components: { RichTextRenderer },
  props: ["data"],
  created() {
    if (!this.data) {
      console.warn("RichTextRenderer: Data not found", this.data);
    }
  },
  methods: {
    rendererNodes() {
      return {
        [BLOCKS.EMBEDDED_ENTRY]: blocksEmbeddedEntryRenderer,
        [BLOCKS.EMBEDDED_ASSET]: embeddedAssetRenderer,
        [INLINES.EMBEDDED_ENTRY]: inlineEmbeddedEntryRenderer,
        [INLINES.HYPERLINK]: linkRenderer,
        [INLINES.ASSET_HYPERLINK]: asssetHyperlinkRenderer,
      };
    },
    rendererMarks() {
      return {
        [MARKS.CODE]: codeRenderer,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.colsec p {
  padding-top: 10px;
  margin: 0px;
}
.sectionColumn2 .cgi-card p {
  margin: 0px;
}
.sectionColumn2 .cgi-card .rich-text h3 {
  margin: 0px;
  padding-bottom: 25px;
}
.usa-grey p:not(:empty) {
  font-family: 'Helvetica',‘Helvetica’,Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
    sans-serif;
  color: #fff;
  text-transform: uppercase;
  background-color: #565c65;
  border-radius: 2px;
  margin-right: 0.25rem;
  margin-top: 2px;
  margin-bottom: 0px;
  width: auto !important;
  padding: 1px 0.5rem;
  display: table;
}
.grid-col-auto .rich-text p {
  margin-top: 2px;
  margin-bottom: 0px;
  display: table;
}
// .first-section p{
//   margin: 22px;
// }
.border-base-lighter .noticedesc .font-sans-xs .rich-text p {
  font-size: 1.09816rem;
}
.border-base-lighter .font-sans-lg {
  font-size: 1.46397rem;
}
.usa-alert__body .usa-alert__text .rich-text p {
  margin-top: 0px;
  display: inline;
}
.usa-alert__body .usa-alert__text .rich-text h4 {
  display: inline-block;
}
</style>
 
