<template>
    <div :class="[data.fields.sectionStyleInstruction]" class="column-horizontal-section">
       <div class="grid-container padding-x-2 tablet:padding-x-5">
        <h2 class="font-sans-xl margin-tb1 text-center">{{ data.fields.sectionHeading }}</h2>
        <rich-text-renderer
          :data="data.fields.sectionCommonTopContent"
        ></rich-text-renderer>
        <div class="grid-row grid-gap-4 main-class" :class="parentClass">
          <div class="sectionColumn1" id="sectionColumn1" :class="[FirstChildClass, getFirstClass, getStep6FirstClass]">
            <rich-text-renderer
              :data="data.fields.sectionColumn1"
            ></rich-text-renderer>
          </div>
          <div class="sectionColumn2" id="sectionColumn2" :class="[secondSecClass, getSecondClass, getStep6SecondClass]">
            <div :class="[BoxSize]" >
              <div :class="SecondChildClass" >
                <rich-text-renderer
                  :data="data.fields.sectionColumn2"
                ></rich-text-renderer>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.fields.sectionCommonBottomContent" class="usa-section">
        <rich-text-renderer
          :data="data.fields.sectionCommonBottomContent"
        ></rich-text-renderer>
      </div>
    </div>
    </div>
 </template>

<script>
function setCssClasses(inst) {
  let class_data = inst.split(" ").map((ins) => ins.split(":"));
  const col1 = class_data.find((v) => v[0] === "all-columns");
  if(col1){
    col1.unshift();
    this.parentClass = col1["1"];
  }

  if(class_data['1']){  
    const col2 = class_data.find((v) => v[0] === "column-2");
    if(col2){
      col2.unshift();
      this.secondSecClass = col2["1"];
      this.SecondChildClass = "cgi-card padding-3 shadow-2 margin-top-2 tablet:margin-top-0";
    }
  }
  if (col1[1] == "70-30") {
    this.FirstChildClass = " tablet:grid-col-8 desktop:grid-col-9";
    this.secondSecClass += "grid-col-12 tablet:grid-col-4 desktop:grid-col-3 ";
  }
  if (col1[1] == "30-70") {
    this.FirstChildClass = " grid-col-12 tablet:grid-col-4";
    this.secondSecClass += " grid-col-12 tablet:grid-col-8";
  }
  if (col1[1] == "30-70") {
    this.FirstChildClass = " grid-col-12 tablet:grid-col-4";
    this.secondSecClass += " grid-col-12 tablet:grid-col-8";
  }
  if (col1[1] == "50-50") {
    this.FirstChildClass = " grid-col-12 tablet:grid-col-6 margin-top-4";
    this.secondSecClass += " grid-col-12 tablet:grid-col-6 margin-top-4";
  }
  if (col1[1] == "60-40") {
    this.FirstChildClass = " grid-col-12 tablet:grid-col-7 padding-2";
    this.secondSecClass += " grid-col-12 tablet:grid-col-5";
  }
  
}
export default {
  name: "column-horizontal-section",
  props: ["data"],
  data() {
    return {
      secondSecClass: "",
      parentClass: "",
      FirstChildClass: "",
      SecondChildClass: "",
    };
  },
  created() {
    let inst = this.data.fields.sectionStyleInstruction;
    setCssClasses.call(this, inst);
  },
  computed: {
    getFirstClass () {
      return this.data.fields.sectionStyleInstruction === 'all-columns:30-70' ? 'grid-col-12 tablet:grid-col-4' : 'no-class'
    },
    getSecondClass () {
      return this.data.fields.sectionStyleInstruction === 'all-columns:30-70' ? 'grid-col-12 tablet:grid-col-8' : 'no-class'
    },
    getStep6FirstClass() {
      return this.data.fields.sectionStyleInstruction === 'all-columns:60-40 bottom-common-section-center-align' ? 'tablet:grid-col-6 desktop:grid-col-5 margin-y-1 text-left' : 'no-class'
    },
    getStep6SecondClass() {
        return this.data.fields.sectionStyleInstruction === 'all-columns:60-40 bottom-common-section-center-align' ? 'tablet:grid-col-6 desktop:grid-col-7 margin-y-1' : 'no-class';       
      },
    BoxSize() {
      // let cssPage; 
       // cssPage = this.data.fields.sectionHeading === 'Photo requirements' ? 'display-flex flex-column flex-align-center':'';
       // cssPage = this.data.fields.sectionHeading === 'Appointment wait times' ? ' wht-bt':'';
      // return cssPage;
      return '';
    }
  },
};
</script>

<style lang="scss" scoped>
  .wht-bt{
    background-color: white;
  }
  .margin-tb1 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  ::v-deep .no-margin {
    padding-left: 1rem;
  }

  .step-2 {
    .usa-section {
      padding: 0 !important;
    }
  }
  .even  {
    .column-horizontal-section {
        padding-bottom: 4rem;
        padding-top: 4rem;
        background-color: #f0f0f0;
    }
  }
  .odd {
    .column-horizontal-section {
      padding-bottom: 4rem;
      padding-top: 4rem;
      background: #FFFFFF;
    }
  }
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 1024px) {
  .bottom-common-section-center-align {
    ::v-deep ol {
      padding: 0;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .step-2{
    .sectionColumn2{
      padding-left: 5%;
      padding-right: 5%;
      h3{
        font-size: 1.06471rem;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .step-2{
    .sectionColumn2{
      padding-left: 15%;
      padding-right: 15%;
      h3{
        font-size: 1.06471rem;
      }
    }
  }
}
</style>