<template>
  <div
    class="grid-col-6 tablet:grid-col-3"
    :class="[data.fields.styleInstructions, getGrid4]"
  >
    <span
      class="display-flex flex-column height-full padding-2 text-center text-white text-no-underline hover:text-underline link-text" :class="data.fields.svgIconCode"
    >
      <span class="svg-lg" v-if="data.fields.svgIconCode === 'email'">
        <svg width="24" height="24" viewBox="0 0 512 512"  :class="homeonly" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
          ></path>
        </svg>
      </span>
      <span class="svg-lg" v-if="data.fields.svgIconCode === 'chat'">
        <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60.02 60.02" style="enable-background:new 0 0 60.02 60.02;" xml:space="preserve" fill="#EEEEEE"  aria-hidden="true" role="presentation" focusable="false"><g>
          <path d="M59.889,47.515L56.05,35.997c2.056-3.615,3.14-7.717,3.14-11.893C59.19,10.819,48.38,0.01,35.095,0.01
          c-8.242,0-15.823,4.223-20.219,11.007c3.089-1.291,6.477-2.007,10.029-2.007C39.294,9.01,51,20.716,51,35.105
          c0,3.996-0.905,7.783-2.518,11.172l10.263,2.701C58.83,48.999,58.915,49.01,59,49.01c0.004,0,0.007,0,0.011,0
          c0.542-0.01,1.009-0.444,1.009-1C60.02,47.83,59.973,47.661,59.889,47.515z"/>
          <path d="M24.905,11.01c-13.286,0-24.095,10.809-24.095,24.095c0,4.176,1.084,8.277,3.14,11.893L0.051,58.694
          c-0.116,0.349-0.032,0.732,0.219,1C0.462,59.899,0.728,60.01,1,60.01c0.085,0,0.17-0.011,0.254-0.033l12.788-3.365
          c3.349,1.694,7.096,2.588,10.863,2.588C38.191,59.199,49,48.391,49,35.105S38.191,11.01,24.905,11.01z M20,27.01h11
          c0.552,0,1,0.447,1,1s-0.448,1-1,1H20c-0.552,0-1-0.447-1-1S19.448,27.01,20,27.01z M36,43.01H15c-0.552,0-1-0.447-1-1s0.448-1,1-1
          h21c0.552,0,1,0.447,1,1S36.552,43.01,36,43.01z M36,36.01H15c-0.552,0-1-0.447-1-1s0.448-1,1-1h21c0.552,0,1,0.447,1,1
          S36.552,36.01,36,36.01z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
        </svg>
      </span>
      <span class="svg-lg" v-if="data.fields.svgIconCode === 'call'">
        <svg width="24" height="24" viewBox="0 0 512 512" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
          ></path>
        </svg>
      </span>
      <span class="svg-lg" v-if="data.fields.svgIconCode === 'skype'">
        <svg width="24" height="24" viewBox="0 0 448 512" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z"
          ></path>
        </svg>
      </span>
      <span
        class="svg-lg"
        :class="data.fields.svgIconCode"
        v-if="data.fields.svgIconCode === 'skype-blue'"
      >
        <svg width="24" height="24" viewBox="0 0 448 512"  aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z"
          ></path>
        </svg>
      </span>
      <span
        class="svg-lg"
        :class="data.fields.svgIconCode"
        v-if="data.fields.svgIconCode === 'person and shield'"
        ><svg width="24" height="24" viewBox="0 0 640 512" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M622.3 271.1l-115.2-45c-4.1-1.6-12.6-3.7-22.2 0l-115.2 45c-10.7 4.2-17.7 14-17.7 24.9 0 111.6 68.7 188.8 132.9 213.9 9.6 3.7 18 1.6 22.2 0C558.4 489.9 640 420.5 640 296c0-10.9-7-20.7-17.7-24.9zM496 462.4V273.3l95.5 37.3c-5.6 87.1-60.9 135.4-95.5 151.8zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 40c0-2.5.8-4.8 1.1-7.2-2.5-.1-4.9-.8-7.5-.8h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c6.8 0 13.3-1.5 19.2-4-54-42.9-99.2-116.7-99.2-212z"
          ></path></svg
      ></span>
      <span
        class="svg-lg"
        :class="data.fields.svgIconCode"
        v-if="data.fields.svgIconCode === 'ribbon'"
        ><svg width="24" height="24" viewBox="0 0 384 512" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
          ></path></svg
      ></span>

      <span
        class="svg-lg"
        :class="data.fields.svgIconCode"
        v-if="data.fields.svgIconCode === 'question'"
        ><svg width="24" height="24" viewBox="0 0 512 512" aria-hidden="true" role="presentation" focusable="false">
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
          ></path></svg
      ></span>

      <span
        class="svg-lg"
        :class="data.fields.svgIconCode" v-if="data.fields.svgIconCode === 'folded page'">
        <svg width="24" height="24" viewBox="0 0 384 512" aria-hidden="true" role="presentation" focusable="false">
          <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
        </svg>
      </span>
      <span
        class="padding-right-1" v-if="data.fields.svgIconCode === 'doc-upload'">
          <svg width="24" height="24" viewBox="0 0 384 512" aria-hidden="true" role="presentation" focusable="false">
            <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path>
          </svg>
      </span> 
      <rich-text-renderer :data="data.fields.ctaLink"></rich-text-renderer>
    </span>
  </div>
</template>
<script>
export default {
  name: "independent-cta",
  props: ["data"],
  computed: {
    getGrid4() {
      return this.data.fields.styleInstructions === "grid-cta-4"
        ? "tablet:grid-col-3"
        : "";
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep a.usa-link {
  text-decoration: none;
  font-weight: bold;
  span {
    display: none;
  }
  &:hover {
    span {
      display: none;
    }
  }
}
.skype-blue {
  svg {
    fill: #0071bc;
  }
}
.cta-icon-center {
  margin: 0 auto;
  ::v-deep p {
    margin: 0;
  }
}
::v-deep .link-text {
  p {
    margin: 0 !important;
  }
}
.padding-right-1{
  svg{
    color: #0071BC;
  }
}
.doc-upload {
  display: -webkit-box;
  padding: 10px 0 0;
  white-space: nowrap; 
}

.btn-link-text {
  ::v-deep .text-white {
    color: #232323 !important;
    font-weight: 600;
    text-decoration: none;
  }
}
</style>
