<template>
  <div class="container">
    <h2>{{ data.fields.heading }}</h2>

    <ul class="steps">
      <li :key="i" v-for="(step, i) of data.fields.howToApplyStep">
        <document-renderer :documentId="step.sys.id" :additionaldata="{ stepCount: i }"></document-renderer>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "how-to-apply",
  props: ["data"],
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Helvetica',Source_Sans_Pro_Web, Helvetica Neue, Helvetica, Roboto, Arial,
    sans-serif;
}

h2 {
  font-size: 1.46rem;
  text-align: center;
}

h1 {
  font-size: 1.46rem;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-top: 1rem;

  li {
    position: relative;
    ::v-deep &:last-child .progress{
      display: none;
    }
  }
}

.step-1 {
  #visa-steps {
    margin-top: 3rem;
  }
}
.step-6{
  h1 {
      margin: 9rem 0 1rem;
    }
  }
#visa-steps {
  padding-top: 4rem;
  padding-bottom: 2rem;
}
@media (min-width: 320px) and (max-width: 767px) {
  ul.steps {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  h2 {
    margin: 2.5rem 0 1rem;
    font-size: 1.2em;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  ul.steps {
    display: flex;
  }
  h2 {
    margin: 2.5rem 0 1rem;
    font-size: 1.2em;
  }
}
</style>



<style lang="scss" scoped>

@media (min-width: 320px) and (max-width: 767px) {
  ul.steps {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  h1 {
    margin: 2.5rem 0 1rem;
  }
  .step-6{
    h1 {
        margin: 5rem 0 1rem;
      }
  }
  #visa-steps {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  ul.steps {
    display: flex;
  }
  h1 {
    margin: 2.5rem 0 1rem;
  }
  .step-6{
    h1 {
        margin: 5rem 0 1rem;
      }
  }
}
</style>
