<template>
  <div class="margin-x-auto" :class="[data.fields.styleInstructions, getMediumWidth]">
    <h3 class="font-sans-xl text-center margin-bottom-2">
      {{ data.fields.subSectionHeading }}
    </h3>
    <rich-text-renderer :data="data.fields.subSectionBody"></rich-text-renderer>
  </div>
</template>

<script>
import { getExchangeRate } from "@/store/api";

export default {
  name: "sub-section",
  props: ["data"],
  data() {
    return {
      StyleClass: "",
    };
  },
  created() {
    getExchangeRate(this.$route.params.countrycode).then(
      (ratio) => {
        this.ratio = ratio;
        // console.log('sub section: this.ratio',this.ratio)
      });
  },
  computed: {
    getMediumWidth() {
      return this.data.fields.styleInstructions === "narrow-encapsulator"
        ? "margin-x-auto maxw-tablet"
        : "";
    }
  },
};
</script>
<style lang="scss" scoped>
  .all-left-align {
    text-align: left;
    h3 {
      text-align: left;
      font-size: 1.34164rem;
    }
  }
  .immigrant-visa{
  #petition-status{
    h3{
    font-size: 1.5em;margin-top: 4rem;}
  }}
  .contact-us {
    #call {
      ::v-deep h2 {
        font-size: 30px;
      }
      ::v-deep p {
        margin: 10px 0 20px;
      }
    }
  }
  ::v-deep #contact-us-call-3{
    .padding-y-5{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .text-bold{
      font-weight: 500;
    }
  } 
</style>