<template>
  <div id="faq-search-results">
    <div class="usa-search usa-search--big margin-y-2" id="faq-search-form">
      <form @submit.prevent="onSearch()">
        <div role="search">
          <label class="usa-sr-only" for="faq-search-input">{{translationKeywords['search-faq'] || 'Search FAQ'}}</label>
          <input
            class="usa-input maxw-full"
            id="faq-search-input"
            type="search"
            name="search"
          />
          <button type="submit" class="usa-button">
            <span class="usa-search__submit-text">{{translationKeywords['search'] || 'Search'}}</span>
            <span class="usa-sr-only"> {{translationKeywords['faq'] || 'FAQ'}}</span>
          </button>
        </div>
      </form>
    </div>
    <div id="faq-search-results-count">
      <span v-html="translationKeywords['results'] || 'results'"></span>
      <span>: {{ searchCount }}</span>
    </div>
    <ol class="usa-search__results">
      <div class="parentli" :key="i" v-for="(ref, i) in data.fields.faqQuestionRefs">
        <document-renderer
          :documentId="ref.sys.id"
          :additionaldata="{ faqSection: faqSection }"
        ></document-renderer>
      </div>
    </ol>
    <ul
      class="grid-row grid-gap-3 mobile-lg:grid-gap-2 margin-0 padding-0"
      style="list-style: none"
    >
      <li
        class="grid-col-12 mobile-lg:grid-col-4 tablet:grid-col-3 margin-y-1"
        :key="i"
        v-for="(ref, i) in data.fields.faqCardRefs"
      >
        <document-renderer
          :documentId="ref.sys.id"
          :additionaldata="{ faqSection: faqSection }"
        ></document-renderer>
      </li>
    </ul>
  </div>
</template>
<script>

import { getCurrencyCode,getTranslationKeywords } from "@/store/api";
import { urlSlug,urlCountryCode,urlLangCode } from "@/helpers/url";

export default {
  name: "faq-section",
  props: ["data"],
  data() {
    return {
      faqSection: this,
      searchCount: 0,
      translationKeywords: {},
    };
  },
  created() {
    this.$on("card-click", () => {
      this.searchCount = 0;
    });
    this.$on("question-shown", () => {
      this.$nextTick(() => this.searchCount++);
    });
  },
  methods: {
    onSearch() {
      const data = document.getElementById("faq-search-input").value;
      this.searchCount = 0;
      this.$emit("question-filter", {
        data: data,
      });
    },
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  },
};
</script>
<style lang="scss" scoped>
.usa-search__results {
  counter-reset: section;
  list-style-type: none;
}

.usa-search__results .displayblock::before {
  counter-increment: section;
  content: counters(section, ".") ". ";
  margin-top: 10px;
  margin-left: -15px;
  font-weight: 400;
  position: absolute;
}
</style>
