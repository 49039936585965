<template> 
    <div class="grid-row">
        <div class="grid-col-auto padding-1 desktop:padding-right-2 text-center" v-if="StyleClassTicked !== '' || StyleClassCrossed !== '' || StyleClassTickedTable != ''">
          <span class="svg-sm" :class="StyleClassTicked">
            <svg width="24" height="24" viewBox="0 0 512 512" role="img" focusable="false" aria-labelledby="svgTickedTitle" aria-describedby="svgTickedDescription">
              <title id="svgTickedTitle">Ticked</title>
              <desc id="svgTickedDescription">Ticked</desc>
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span class="svg-sm" :class="StyleClassCrossed">
            <svg width="24" height="24" viewBox="0 0 512 512" role="img" focusable="false" aria-labelledby="svgCrossedTitle" aria-describedby="svgCrossedDescription">
              <title id="svgCrossedTitle">Crossed</title>
              <desc id="svgCrossedDescription">Crossed</desc>
              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
            </svg>
          </span>
          <span class="svg-sm" :class="StyleClassTickedTable">
            <svg width="24" height="24" viewBox="0 0 512 512" role="img" focusable="false" aria-labelledby="svgTickedTblTitle" aria-describedby="svgTickedTblDescription">
              <title id="svgTickedTblTitle">Ticked</title>
              <desc id="svgTickedTblDescription">Ticked</desc>
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
          </span>
        </div>
        <div v-if="this.data.fields.isHtml"  v-html ="data.fields.htmlContent"> </div>
        <div v-else class="grid-col-auto">
            <rich-text-renderer :class="StyleClass" 
            :data="data.fields.textContent"
            ></rich-text-renderer> 
        </div>


    </div>
</template>

<script>

export default {
    name: "block-of-text",
    props: ["data"],
    data() {
    return {
      StyleClass: "",
      StyleClassCrossed: "",
      StyleClassTicked: "",
      StyleClassTickedTable: ""
    };
  },
  created() {
    if(this.data.fields.styleInstructions == 'text-highlight-dark-grey'){
        this.StyleClass = "usa-grey";
    }
    if(this.data.fields.styleInstructions == 'dark-grey-crossed-item'){
        this.StyleClassCrossed = "dark-grey-crossed";
    }

    if(this.data.fields.styleInstructions == 'tick'){
        this.StyleClassTickedTable = "svg-baseline"
    }

    if(this.data.fields.styleInstructions == 'dark-grey-ticked-item' || this.data.fields.styleInstructions == 'cross'){
        this.StyleClassTicked = "dark-grey-ticked";        
    } 
  },
};
</script>

<style scoped lang="scss">
.svg-sm{
    display: none;
}
.dark-grey-crossed{
    display:block;
}
.dark-grey-ticked{
    display:block;
}
.tick{
    display:block;
}
.svg-baseline{
    display:block;
}
#more-res-group-appt-ticked-text {
    flex-wrap: nowrap;
    .grid-col-auto {
        flex: inherit;
    }
}
</style>