<template>
  <div v-if = "!this.$route.name.includes('depositslip')" class="footer-links-holder" v-bind:class="{ active: footerToggle }">
    <h1 class="footer-heading desktop-only">&nbsp;</h1>
    <h1 class="footer-heading mobile-only" @click="toggleFooter()">General Information</h1>
    <rich-text-renderer
      :data="data.fields.details"
      class="footer-links"
    ></rich-text-renderer>
  </div>
</template>

<script>
const insertSpace = () => "&nbsp;";
export default {
  name: "footer-middle",
  props: ["data"],
  computed: { insertSpace },
  data() {
    return {
      footerToggle: false,
    };
  },
  created() {
    this.$root.$on("footer:expand", (id) => {
      if (id !== 2) {
        this.footerToggle = false;
        return;
      }
      this.footerToggle = !this.footerToggle;
    });
  },
  methods: {
    toggleFooter(e) {
      this.$root.$emit("footer:expand", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
    height: 28px;
  }
  @media (min-width: 320px) {
    .mobile-only {
      display: block;
    }
    .desktop-only {
      display: none;
    }
  }

  @media (min-width: 480px) {
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: block;
    }
  }
</style>
