<template>
    <div>
        <div class="go-live-first-container">

            <img class = "go-live-img" width="18" height="20" src="/assets/img/GoLiveImageNew.png" alt="PDF icon" 
            v-if="this.imgflag.startsWith('true')"/>
        </div>
        <div class='go-live-container' v-html ="splashmsg"></div>
    </div>
</template>
  
<script>
import { urlSlug,urlLangCode,urlCountryCode } from "@/helpers/url";
import {
  getCountryName,
  getCountrySplashMsg,
  getCountrySplashImgFlag
} from "@/store/api";

console.log("Go Live Landing Page Component.");
export default {
    name: "landing",
    data: () => ({
        splashmsg: "",
        country: "",
        imgflag:""
    }),
    created() {
        this.countryCode = urlCountryCode();
        getCountryName(this.countryCode).then((country) => {
            this.country = country;
        });
        getCountrySplashMsg(this.countryCode).then((splashmsg) => {
            this.splashmsg = splashmsg;
        });
        getCountrySplashImgFlag(this.countryCode).then((imgflag) => {
            this.imgflag = imgflag;
        });
        
        
  }
};
</script>

<style lang="scss" scoped>
.go-live-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.go-live-first-container {
    max-width: 100%;
    padding: 0%;
}

.go-live-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

.go-live-p {
    margin: 0cm;
    font-size: 30px;
    font-family: 'Helvetica',"Calibri", sans-serif;
}

.go-live-li {
    margin: 0cm;
    font-size: 20px;
    font-family: 'Helvetica',"Calibri", sans-serif;
}

.go-live-section {
    display: block;
}

@media only screen and (max-width: 600px) {
    .go-live-img {
        width: 100%;
        height: 100%;
    }

    .go-live-p {
        margin: 0cm;
        font-size: 20px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }

    .go-live-li {
        margin: 0cm;
        font-size: 15px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }
}

@media only screen and (min-width: 600px) {
    .go-live-img {
        width: 100%;
        height: 100%;
    }

    .go-live-p {
        margin: 0cm;
        font-size: 24px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }

    .go-live-li {
        margin: 0cm;
        font-size: 21px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }
}

@media only screen and (min-width: 768px) {
    .go-live-img {
        width: 100%;
        height: 100%;
    }

    .go-live-p {
        margin: 0cm;
        font-size: 28px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }

    .go-live-li {
        margin: 0cm;
        font-size: 24px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }
}

@media only screen and (min-width: 992px) {
    .go-live-img {
        width: 100%;
        height: 100%;
    }

    .go-live-p {
        margin: 0cm;
        font-size: 32px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }

    .go-live-li {
        margin: 0cm;
        font-size: 28px;
        font-family: 'Helvetica',"Calibri", sans-serif;
    }
}
</style>
