
function install(Vue, options) {
  Vue.mixin({
    mounted() {
      if (this.$route.hash && this.$route.hash === '#' + this.$el.id) {
        setTimeout(() => {
          const pos = this.$el.getBoundingClientRect();
          window.scrollTo(0, pos.top);
        }, 800);
      }
    }
  });
}

export default {
  install: install
}