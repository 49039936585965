<template>
  <span>
    <img
      :src="imgUrl"
      :alt="data.fields.description || 'image'"
      v-if="this.data.fields.file.contentType.startsWith('image')"
    />

    <a
      v-if="this.data.fields.file.contentType.includes('pdf')"
      :href="data.fields.file.url"
      target="_blank"
    >
      {{ pdfLinkDisplayText }}
      <img width="18" height="20" src="/assets/img/pdf.svg" alt="PDF icon" />
    </a>
  </span>
</template>

<script>
import { urlSlug } from "@/helpers/url";
import _ from "lodash";

export default {
  name: "asset-component",
  props: ["data"],
  data() {
    return {
      isImage: false,
      isPdf: false,
      pdfLinkDisplayText: "",
    };
  },
  computed: {
    imgUrl() {
      return "http:" + this.data.fields.file.url;
    },
  },
  created() {
    this.pdfLinkDisplayText = _.get(
      this,
      "$vnode.data.attrs.additionaldata[0].value",
      ""
    );
  },
};
</script>

<style lang="scss" scoped>
</style>



