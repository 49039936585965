<template >
  <div  v-if = "!this.$route.name.includes('depositslip')" class="usa-footer__secondary-section padding-y-2 font-sans-3xs text-white">
    <div  class="grid-container">
      <div  class="grid-row grid-gap">
        <div  class="grid-col-12 mobile-lg:grid-col-fill text-light footer-text" v-if="dataLoaded">
          <rich-text-renderer :data="pageData.fields.textContent"></rich-text-renderer>
        </div>
        <div class="grid-col-12 mobile-lg:grid-col-auto padding-top-1 mobile-lg:padding-top-0 footer-text" v-if="cgiDataLoaded">
          <rich-text-renderer :data="cgiData.fields.textContent"></rich-text-renderer>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { getDataByFieldSlug } from "@/store/api";
  export default {
    name: "footer-component",
    data() {
      return {
        dataLoaded: false,
        cgiDataLoaded: false,
        pageData: {},
        cgiData: {}
      };
    },
    created() {
      getDataByFieldSlug("blockOfText", "sub-footer", this.$route.params.countrycode, this.$route.params.langcode).then((pageData) => {
        this.dataLoaded = true;
        if (!pageData) {
          console.log('404');
        }
        this.pageData = pageData;
      });

      getDataByFieldSlug("blockOfText", "cgi-footer", 'this.$route.params.countrycode', this.$route.params.langcode).then((data) => {
        this.cgiDataLoaded = true;
        if (!data) {
          console.log('404');
        }
        this.cgiData = data;
      });
    },
  };
</script>

<style scoped lang="scss">
  .footer-text {
    .rich-text {
      ::v-deep p { 
        font-size: 0.86507rem; 
      }
    }
  }
</style>