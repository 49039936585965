import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { registerComponents } from '@/renderer/GlobalComponents';
import hashScroll from "@/plugins/hash-scroll";
import VueHtmlToPaper from 'vue-html-to-paper';
import AsyncComputed from 'vue-async-computed';

Vue.use(AsyncComputed);
registerComponents();
Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    setPageTitle(title) {
      document.title = title;
    }
  }
});
Vue.use(hashScroll);
Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'width=1024',
    'height=800',
    'location=no',
    'menubar=no',
    'resizable=no',
    'scrollbars=no',
    'status=no',
  ],
  styles: [
    '/assets/css/print.css'
  ]
});

const vue = new Vue({
  router,
  render: h => h(App)
});
vue.$mount('#app');
