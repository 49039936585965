<template>
    <div class="grid-col-12 tablet:grid-col-4 margin-y-1">
        <div class="cgi-card padding-x-2 padding-y-4 text-center" :class="data.fields.sectionStyleInstruction">
            <div class="svg-xl" v-if="data.fields.cardIcon === 'biometric-thumb-print'">
                <svg width="24" height="24" viewBox="0 0 512 512" class="svgimg biometrics" :class="Biometric" aria-hidden="true" role="presentation" focusable="false">
                  <path d="M256.12 245.96c-13.25 0-24 10.74-24 24 1.14 72.25-8.14 141.9-27.7 211.55-2.73 9.72 2.15 30.49 23.12 30.49 10.48 0 20.11-6.92 23.09-17.52 13.53-47.91 31.04-125.41 29.48-224.52.01-13.25-10.73-24-23.99-24zm-.86-81.73C194 164.16 151.25 211.3 152.1 265.32c.75 47.94-3.75 95.91-13.37 142.55-2.69 12.98 5.67 25.69 18.64 28.36 13.05 2.67 25.67-5.66 28.36-18.64 10.34-50.09 15.17-101.58 14.37-153.02-.41-25.95 19.92-52.49 54.45-52.34 31.31.47 57.15 25.34 57.62 55.47.77 48.05-2.81 96.33-10.61 143.55-2.17 13.06 6.69 25.42 19.76 27.58 19.97 3.33 26.81-15.1 27.58-19.77 8.28-50.03 12.06-101.21 11.27-152.11-.88-55.8-47.94-101.88-104.91-102.72zm-110.69-19.78c-10.3-8.34-25.37-6.8-33.76 3.48-25.62 31.5-39.39 71.28-38.75 112 .59 37.58-2.47 75.27-9.11 112.05-2.34 13.05 6.31 25.53 19.36 27.89 20.11 3.5 27.07-14.81 27.89-19.36 7.19-39.84 10.5-80.66 9.86-121.33-.47-29.88 9.2-57.88 28-80.97 8.35-10.28 6.79-25.39-3.49-33.76zm109.47-62.33c-15.41-.41-30.87 1.44-45.78 4.97-12.89 3.06-20.87 15.98-17.83 28.89 3.06 12.89 16 20.83 28.89 17.83 11.05-2.61 22.47-3.77 34-3.69 75.43 1.13 137.73 61.5 138.88 134.58.59 37.88-1.28 76.11-5.58 113.63-1.5 13.17 7.95 25.08 21.11 26.58 16.72 1.95 25.51-11.88 26.58-21.11a929.06 929.06 0 0 0 5.89-119.85c-1.56-98.75-85.07-180.33-186.16-181.83zm252.07 121.45c-2.86-12.92-15.51-21.2-28.61-18.27-12.94 2.86-21.12 15.66-18.26 28.61 4.71 21.41 4.91 37.41 4.7 61.6-.11 13.27 10.55 24.09 23.8 24.2h.2c13.17 0 23.89-10.61 24-23.8.18-22.18.4-44.11-5.83-72.34zm-40.12-90.72C417.29 43.46 337.6 1.29 252.81.02 183.02-.82 118.47 24.91 70.46 72.94 24.09 119.37-.9 181.04.14 246.65l-.12 21.47c-.39 13.25 10.03 24.31 23.28 24.69.23.02.48.02.72.02 12.92 0 23.59-10.3 23.97-23.3l.16-23.64c-.83-52.5 19.16-101.86 56.28-139 38.76-38.8 91.34-59.67 147.68-58.86 69.45 1.03 134.73 35.56 174.62 92.39 7.61 10.86 22.56 13.45 33.42 5.86 10.84-7.62 13.46-22.59 5.84-33.43z"></path>
                </svg>
            </div>
            <div class="svg-xl" v-if="data.fields.cardIcon === 'interview-waiver-form-submit'">
                <svg width="24" height="24" viewBox="0 0 576 512" class="svgimg interview-waiver" :class="Interview" aria-hidden="true" role="presentation" focusable="false">
                  <path d="M432 192l-.5-175.4c0-8.8-7.2-16-16-16L159.7 0c-8.9 0-16.2 7.2-16.2 16.2L144 192h288zM0 320v160c0 17.7 14.3 32 32 32h512c17.7 0 32-14.3 32-32V320H0zm574.7-32c.4-.5.5-.7.8-1.2L520 148.2c-4.9-12.1-16.6-20.1-29.7-20.1h-26.6l.2 64 16 32H95.5l16-32h.5l-.2-64H85.7c-13.1 0-24.8 8-29.7 20.1L.4 286.8c.3.5.5.7.8 1.2h573.5z"></path>
                </svg>
            </div>
            <div class="svg-xl" v-if="data.fields.cardIcon === 'passport'">
                <svg width="24" height="24" viewBox="0 0 448 512" class="svgimg passport-collection" :class="Passport"  aria-hidden="true" role="presentation" focusable="false">
                  <path d="M129.62 176h39.09c1.49-27.03 6.54-51.35 14.21-70.41-27.71 13.24-48.02 39.19-53.3 70.41zm0 32c5.29 31.22 25.59 57.17 53.3 70.41-7.68-19.06-12.72-43.38-14.21-70.41h-39.09zM224 286.69c7.69-7.45 20.77-34.42 23.43-78.69h-46.87c2.67 44.26 15.75 71.24 23.44 78.69zM200.57 176h46.87c-2.66-44.26-15.74-71.24-23.43-78.69-7.7 7.45-20.78 34.43-23.44 78.69zm64.51 102.41c27.71-13.24 48.02-39.19 53.3-70.41h-39.09c-1.49 27.03-6.53 51.35-14.21 70.41zM416 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h352c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32zm-80 416H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h224c8.8 0 16 7.2 16 16s-7.2 16-16 16zm-112-96c-70.69 0-128-57.31-128-128S153.31 64 224 64s128 57.31 128 128-57.31 128-128 128zm41.08-214.41c7.68 19.06 12.72 43.38 14.21 70.41h39.09c-5.28-31.22-25.59-57.17-53.3-70.41z"></path>
                </svg>
            </div>
            <rich-text-renderer :data="data.fields.cardBody"></rich-text-renderer>
        </div>
    </div>
</template>

<script>
export default {
  name: "vac-card",
  props: ["data"],
  data() {
    return {
      Biometric: "",
      Interview: "",
      Passport:""
    };
  },
};
</script>
<style lang="scss" scoped> 
</style>