// to compute external links
export const HOST_NAME = 'ustraveldocs.com';
export const HEADER_CONTENT_ID = '6EI8ictpxoMVnga9rRytoR';
export const FOOTER_CONTENT_ID = '2tQxIwpF8BpDvJdW5hKfS';
export const SUB_FOOTER_ID = '3ZxfVIvTztp5cDCW2qJVI1'
export const API_ENVIRONMENTS = ['master', 'russia-dev','Staging'];
export const ENVIRIONMENT = process.env.NODE_ENV;
export const LABEL_FOR_LANGING_PG = "Show countries that begin with ";
export const LABEL_FOR_STEP_5 = "Visa application center locations For";
export const config = {
  development: {
    accessToken: 'cAru_8SCspcitiU81TWE79GQj5v0CU00xnrbUP4Y5MQ',
    spaceId: 'pitqj5emwdvc',
    apiEnvironment: API_ENVIRONMENTS[0],
    exchangeAPI: "http://demo2814691.mockable.io/pa/india",
    contentURL: "https://cdn.contentful.com/",
  },
  production: {
    accessToken: 'cAru_8SCspcitiU81TWE79GQj5v0CU00xnrbUP4Y5MQ',
    spaceId: 'pitqj5emwdvc',
    apiEnvironment: API_ENVIRONMENTS[0],
    exchangeAPI: "https://www.ustraveldocs.com/publicexchangerate",
    contentURL: "https://cdn.contentful.com/",
  },
  preview: {
    accessToken: 'bZLAHApPWlRtoLVh5BjQXzvw156T7eAZD2owCtEKX0E',
    spaceId: 'pitqj5emwdvc',
    apiEnvironment: API_ENVIRONMENTS[0],
    exchangeAPI: "https://www.ustraveldocs.com/publicexchangerate",
    contentURL: "https://cdn.contentful.com/",
  }
};