<template>
  <section class="grid-col-12 tablet:grid-col-3 margin-y-1 card-gap">
    
    <div class="cgi-card display-flex flex-column text-center">
      <div class="cgi-card__head padding-1 bg-primary text-white">
        <h3 class="font-sans-md">{{ data.fields.cardHeading }} </h3>
      </div>
      <div class="cgi-card__body padding-2 grid-col-fill usa-prose">
        <rich-text-renderer
            :data="data.fields.cardBody"
          ></rich-text-renderer>
      </div>
      <div class="cgi-card__foot padding-2 bg-primary-dark text-white">
        <div class="font-sans-2xl text-bold">${{ data.fields.visaFeeAmount }}</div>
        <div v-if="!invalidExchangeRate">

          <span
            data-exchange-rate="160"
            data-to-fixed="2"
            data-exchange-rate-updated="true"
            >{{ totalFinalFee().toFixed(2) }}</span
          >
          {{ currency }}
        </div>
        <div v-if="!data.fields.visaFeeRefundableFlag">
           {{ translationKeywords['non-refundable'] || 'Non-refundable' }}
          <span class="svg-baseline">
            <svg width="24" height="24" viewBox="0 0 216 146" role="img" focusable="false" aria-labelledby="svgTitle" aria-describedby="svgDescription">
            <title id="svgTitle">Important Message</title>
            <desc id="svgDescription">Important Message</desc>
              <path
                d="M162.18 41.592c-5.595-9.586-13.185-17.176-22.771-22.771-9.588-5.595-20.055-8.392-31.408-8.392-11.352 0-21.822 2.797-31.408 8.392-9.587 5.594-17.177 13.184-22.772 22.771-5.596 9.587-8.393 20.057-8.393 31.408 0 11.352 2.798 21.82 8.392 31.408 5.595 9.585 13.185 17.176 22.772 22.771 9.587 5.595 20.056 8.392 31.408 8.392s21.822-2.797 31.408-8.392c9.586-5.594 17.176-13.185 22.771-22.771 5.594-9.587 8.391-20.057 8.391-31.408 0-11.352-2.797-21.822-8.39-31.408zM97.572 26.071c0-.761.244-1.385.733-1.874.489-.488 1.114-.733 1.874-.733h15.644c.76 0 1.385.245 1.872.733.488.489.734 1.113.734 1.874v13.036c0 .76-.246 1.385-.734 1.873-.487.489-1.112.733-1.872.733h-15.644c-.76 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.873V26.071zm31.285 86.036c0 .76-.246 1.385-.733 1.872-.487.489-1.112.733-1.874.733h-36.5c-.761 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.873V99.07c0-.762.244-1.385.733-1.874.489-.488 1.114-.733 1.874-.733h7.822V70.392H89.75c-.761 0-1.385-.244-1.874-.733-.489-.488-.733-1.113-.733-1.874V54.75c0-.761.244-1.385.733-1.874.489-.489 1.114-.733 1.874-.733h26.073c.76 0 1.385.244 1.872.733.488.489.734 1.113.734 1.874v41.714h7.82c.761 0 1.386.245 1.874.733.487.488.733 1.113.733 1.874v13.036z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import { getCurrencyCode,getTranslationKeywords } from "@/store/api";
import { urlCountryCode,urlLangCode } from "@/helpers/url";
import { getExchangeRate, getExchangeRateExpiry } from "@/store/api";

export default {
  name: "visa-type-fee-block",
  props: ["data"],
  data(){
    return{
      currency: '',
      translationKeywords: {},
      exchangeRate :  '',
      totalFee : '',
      invalidExchangeRate:false

    }
  },
  created() {
    const countryCode = urlCountryCode();
    getCurrencyCode(countryCode).then(currency => this.currency = currency);
 //   getExchangeRate(countryCode).then(exchangeRate => this.exchangeRate = exchangeRate);
    getExchangeRate(countryCode).then(exchangeRate => 
  		       {
  		         this.exchangeRate = exchangeRate
  		         // In api null is set for 404 Not Found
  		         if  ((exchangeRate === null) || (exchangeRate === 'NaN') || (exchangeRate === ''))
  		            {
  		                 this.invalidExchangeRate = true;
  		             }
  		       });

  },
  methods: {
    totalFinalFee: function () {
      return this.totalFee = parseFloat(this.data.fields.visaFeeAmount) * parseFloat(this.exchangeRate);
    }
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  }
};
</script>
<style lang="css">
.usa-prose .rich-text p {
  font-size: 1.46397rem;
  margin: 0;
  line-height: 1.32155;
}
.usa-prose .rich-text p a {
  font-size: 1.06471rem;
  line-height: 1.22155;
}
.card-gap {
  padding-left: .5rem;
  padding-right: .5rem;
}
</style>