<template>
  <div
    class="grid-row border-1px border-base-lighter"
    :class="data.fields.styleInstructions"
    style="margin-top: 15px"
  >
    <div
      class="grid-col-12 tablet:grid-col-4"
      style="background-color: #bf2841; border-right: 10px solid #5284c3"
    >
      <div
        class="font-sans-lg padding-x-2 padding-y-1 text-white noticeleftsec"
      >
        <rich-text-renderer
          :data="data.fields.noticeLeftSectionText"
        ></rich-text-renderer>
      </div>
    </div>
    <div
      class="grid-col-12 tablet:grid-col-8 noticedesc"
      style="border-left: 3px solid #0d4575"
    >
      <div class="font-sans-xs padding-x-2 padding-y-1">
        <rich-text-renderer
          :data="data.fields.noticeDescription"
        ></rich-text-renderer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "notice-variation-with-text",
  props: ["data"],
};
</script>
<style lang="scss" scoped>
::v-deep .font-sans-lg {
  p {
    font-size: 1.46397rem !important;
    margin: 0 !important;
  }
}
::v-deep .font-sans-xs {
  p {
    font-size: 0.99816rem !important;
    margin: 0 !important;
  }
}
</style>