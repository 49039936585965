import axios from 'axios'
import { ENVIRIONMENT, config } from "@/config";
import xmlParser from "fast-xml-parser";
const accessToken = config[ENVIRIONMENT].accessToken;
const API_URL = `${config[ENVIRIONMENT].contentURL}/spaces/${config[ENVIRIONMENT].spaceId}/environments/${config[ENVIRIONMENT].apiEnvironment}`;

export async function getSystemGlobalResources() {
  const query = new URLSearchParams({
    access_token: accessToken,
    content_type: "resourceGroup",
    'fields.slug': "sys-global-res",
    'fields.languageCode[match]': "en",
    'fields.countryCodes[match]': 'ALL',
  });

  const res = await axios.get(`${API_URL}/entries?${query.toString()}`);
  return res.data.items[0] || {};
}

export async function getLanguageSpecificResources(langCode) {
  const firstQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: "resourceGroup",
    'fields.slug': "lang-global-resource",
    'fields.languageCode[match]': langCode,
    'fields.countryCodes[match]': 'ALL',
  });

  const firstCall = await axios.get(`${API_URL}/entries?${firstQuery.toString()}`);
  if (firstCall.data.total > 0) return firstCall.data.items[0];

  const secondQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: "resourceGroup",
    'fields.slug': "lang-global-resource",
    'fields.languageCode[match]': "en",
    'fields.countryCodes[match]': 'ALL',
  });

  const secondCall = await axios.get(`${API_URL}/entries?${secondQuery.toString()}`);
  if (secondCall.data.total > 0) return secondCall.data.items[0];
  return {};
}

export async function getCountryResourceGroup(countryCode) {
  const query = new URLSearchParams({
    access_token: accessToken,
    content_type: "resourceGroup",
    'fields.slug': "country-resource-group",
    'fields.languageCode[match]': "en",
    'fields.countryCodes[match]': countryCode,
  });

  const res = await axios.get(`${API_URL}/entries?${query.toString()}`);
  console.log("calling from resourceGroup res => ",res.data.items[0]||{});
  return res.data.items[0] || {};
}

export async function getDataByContentType(contentType, pageSlug, country, lang) {
  const search = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.pageSlug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes[match]': country,
  });
  const primaryCall = await axios.get(`${API_URL}/entries?${search.toString()}`);

  if (primaryCall.data.items[0]) {
    return primaryCall.data.items[0];
  }

  const secondarySearch = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.pageSlug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes': 'ALL',
  });


  const secondaryCall = await axios.get(`${API_URL}/entries?${secondarySearch.toString()}`);
  if (secondaryCall.data.items[0]) {
    return secondaryCall.data.items[0];
  }

  console.error("PAGE DATA NOT FOUND, RESPONSES ARE => ", [primaryCall, secondaryCall]);
  return data;
}

export async function getDataByParams(contentType, slug, countryCode, langCode) {
  const url = params => `${API_URL}/entries/?${params.toString()}`;
  //primary call
  //content type id + language code match + country code match 
  const primaryQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,

    'fields.slug': slug,
    'fields.countryCodes[match]': countryCode,
    'fields.languageCode[match]': langCode,
  });
  const primaryCall = await axios.get(url(primaryQuery));
  if (primaryCall.data.total > 0) return primaryCall.data.items[0];

  //secondary call
  //content type id + language code match + country code matches ALL
  const secondaryQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': slug,
    'fields.countryCodes[match]': 'ALL',
    'fields.languageCode[match]': langCode,
  });
  const secondaryCall = await axios.get(url(secondaryQuery));
  if (secondaryCall.data.total > 0) return secondaryCall.data.items[0];

  //Last call 
  const lastCallQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': slug,
    'fields.languageCode[match]': 'en',
  });

  const lastCall = await axios.get(url(lastCallQuery));
  return lastCall.data.items[0];

}

async function sleep(mlsec) {
  return new Promise(resolve => setTimeout(resolve, mlsec));
}

export async function getDataByDocumentId(id, timesCall = 0) {
  const url = `${API_URL}/entries/${id}?access_token=${accessToken}`
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log("status code ", err.response.status);
    console.log("Error => ", err);
    console.dir(err);
    console.log(`Retrying.. ${timesCall + 1} for : ${id}`);
    if (err.response.status == 429 && timesCall < 3) {
      await sleep(100);
      return getDataByDocumentId(id, timesCall + 1);
    }
    throw err;
  }
}

export async function getAssetData(id) {
  const url = `${API_URL}/assets/${id}?access_token=${accessToken}`
  const response = await axios.get(url)
  return response.data
}

// async function getLocaleData(countryCode) {
//   if (getLocaleData.__cache) {
//     return getLocaleData.__cache;
//   };
//   const apiUrl = `${API_URL}/entries?access_token=${accessToken}&content_type=resourceGroup&fields.title%5Bmatch%5D=${countryCode}&localedata`;
//   const response = await axios.get(apiUrl);
//   getLocaleData.__cache = response.data;
//   return getLocaleData.__cache;
// }

//updated
export async function isRedirectToHomeOnLangChange(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  return data.fields.resJson["lang-change-nav-to-home"];
}

// updated
export async function getCurrencyCode(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson["currency-code"]) return data.fields.resJson["currency-code"];
  return "<span style='color: red;'>Currency Code not found</span>"
}

// updated
export async function getGroupMaxQty(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  return data.fields.resJson["group-max-qty"];
}

// updated
export async function getGroupMinQty(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  return data.fields.resJson["group-min-qty"];
}

// updated
export async function getLanguages(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  return data.fields.resJson["languages"];
}

//pooja, 23-nov-22, get ref-digits from country resource
export async function getRefDigit(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson["ref-digits"]) return data.fields.resJson["ref-digits"];
  return 10;
}
//
export async function getRTLCountry(countryCode, langCode) {
  const data = await getCountryResourceGroup(countryCode, langCode);
  return data.fields.resJson["lang-text-align"];
}

//pooja, 23-nov-22, get barcode url from country resource
export async function getBarcodeUrl(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson["barcode_url"]) return data.fields.resJson["barcode_url"];
  return "<span style='color: red;'>Barcode Url not found</span>";
}

// updated
export async function getLanguageLabels() {
  const data = await getSystemGlobalResources();
  return data.fields.resJson.languages;
}
// updated
export async function getLanguageUrlLabels() {
  const data = await getSystemGlobalResources();
  return data.fields.resJson["atlas-langs"];
}
// updated
// Avnish 09-May-2023 Australia country bar code number formate condition put
export async function getWeights(langCode) {
  const data = await getLanguageSpecificResources(langCode);
  if(data.fields.resJson["weights"] == undefined){
    return 'No data for weights';
  }
  return data.fields.resJson["weights"];
}
// updated
export async function getVisaTypes(langCode) {
  const data = await getLanguageSpecificResources(langCode);
  return data.fields.resJson["visa-types"];
}
export async function getUStdCurrencyData(countryCode) {
  if (getUStdCurrencyData.__cache) return getUStdCurrencyData.__cache;
  const data = await getCountryResourceGroup(countryCode);
  if(data.fields == undefined){
    return 'No data for exchangerate';
  }  
  const countryName = data.fields.resJson["atlas-ex-rate-api-key"];
  //countryName = countryName +'aa';
  const API = config[ENVIRIONMENT]["exchangeAPI"];
  var response;
  try {
    // Avnish, 07-June-2023, Exchange Url changes
    if(data.fields.resJson["ExchangeURL"]) {
      response = await axios.get(data.fields.resJson["ExchangeURL"], {
        params: {}
      });
    }
    else {
      response = await axios.get('/publicexchangerate', {
        params: {
          country: countryName
        }
      });
    }
    console.log("ExchangeURL:::::: response: ", response, data.fields.resJson["ExchangeURL"]);
  } catch (err) {
    console.error("Error response:");
    // console.error(err.response.data);    // ***
    console.error(err.response.status);  // ***
    // console.error(err.response.headers); // ***
    return 'No data found for exchangerate API Failed';
  } finally {
    console.log(response);
    if(response == undefined)    return 'No data found for exchangerate API Failed';
    const jsonData = xmlParser.parse(response.data);
    // setting null as response is 404 Not Found after Xml parsing
  	console.log("jsonData ",jsonData?.response);
  	if(jsonData?.response === "404 Not Found"){
  		       getUStdCurrencyData.__cache = null;
  		       return getUStdCurrencyData.__cache;
  	} 
    getUStdCurrencyData.__cache = jsonData.response;
    return getUStdCurrencyData.__cache;
  }
}

export async function getExchangeRate(countryCode) {
  const data = await getUStdCurrencyData(countryCode);
  if(data?.ratio == undefined){
    // return data;
    // Avnish 19-May-2023, if data grtting undefined then get from country-corrency-ISO.
    const country_resource_group = await getCountryResourceGroup(countryCode);
    if(country_resource_group['fields'] == undefined || country_resource_group['fields']['resJson'] == undefined || country_resource_group['fields']['resJson']['country-currency-ISO'] == undefined) {
      return data;
    }
    getUStdCurrencyData.__cache = null;
    const country_currency_iso = country_resource_group['fields']['resJson']['country-currency-ISO'];
    const exchange_rate_data = await getUStdCurrencyData(country_currency_iso);
    console.log("exchange_rate_data::",exchange_rate_data)

    if (!Number.isFinite(Number(exchange_rate_data.ratio))) {
      console.log("Invalid exchange rate");
      return "Invalid exchange rate";
    }
    return exchange_rate_data.ratio;
  }
  if (!Number.isFinite(Number(data.ratio))) {
    console.log("Invalid exchange rate");
    return "Invalid exchange rate";
  }
  return data.ratio;
}

export async function getExchangeRateExpiry(countryCode) {
  const data = await getUStdCurrencyData(countryCode);
  if(data.expiration_date == undefined) {
    // Avnish 19-May-2023, if data grtting undefined then get from country-corrency-ISO.
    const country_resource_group = await getCountryResourceGroup(countryCode);
    if(country_resource_group['fields'] == undefined || country_resource_group['fields']['resJson'] == undefined || country_resource_group['fields']['resJson']['country-currency-ISO'] == undefined) {
      return "<span style='color: red;'>Expiry date not found exchangerate API Failed<span style='color: red;'>";
    }
    getUStdCurrencyData.__cache = null;
    const country_currency_iso = country_resource_group['fields']['resJson']['country-currency-ISO'];
    const exchange_rate_data = await getUStdCurrencyData(country_currency_iso);
    if (exchange_rate_data?.expiration_date) return exchange_rate_data.expiration_date;
    return "<span style='color: red;'>Expiry date not found exchangerate API Failed<span style='color: red;'>";
  }
  else {
    if (data?.expiration_date) return data.expiration_date;
    return "<span style='color: red;'>Expiry date not found exchangerate API Failed<span style='color: red;'>";
  }
}

export async function getTranslationKeywords(langCode) {
  const data = await getLanguageSpecificResources(langCode);
  return data.fields.resJson.keywords;
}

export async function getCountryName(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson?.country) return data.fields.resJson.country;
  return "<span style='color: red;'>Country Name not found</span>"
}
//Pooja, 04-oct-23, lading page splash message
export async function getCountrySplashMsg(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson['splash-msg'] == undefined){
    return "<p> Splash message not found</p> "
  }
  if (data?.fields?.resJson['splash-msg']) return data.fields.resJson['splash-msg'];
}

// img flash for splash
export async function getCountrySplashImgFlag(countryCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson['img-flag'] == undefined){
    return "<p> Splash message not found</p> "
  }
  if (data?.fields?.resJson['img-flag']) return data.fields.resJson['img-flag'];
}


// Sub Footer 
export async function getDataByFieldSlug(contentType, pageSlug, country, lang) {
  const search = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes[match]': country,
  });
  const primaryCall = await axios.get(`${API_URL}/entries?${search.toString()}`);

  if (primaryCall.data.items[0]) {
    return primaryCall.data.items[0];
  }

  const secondarySearch = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes': 'ALL',
  });


  const secondaryCall = await axios.get(`${API_URL}/entries?${secondarySearch.toString()}`);
  if (secondaryCall.data.items[0]) {
    return secondaryCall.data.items[0];
  }

  const lastCallQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': 'en',
  });

  const lastCall = await axios.get(`${API_URL}/entries?${lastCallQuery.toString()}`);
  if (lastCall.data.items[0]) {
    return lastCall.data.items[0];
  }

  console.error("DATA NOT FOUND, RESPONSES ARE => ", [primaryCall, secondaryCall, lastCall]);
  return data;
}

export async function getDataByForTranslation(contentType, pageSlug, country, lang) {
  const find = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes[match]': country,
  });
  const primaryCall = await axios.get(`${API_URL}/entries?${find.toString()}`);

  if (primaryCall.data.items[0]) {
    return primaryCall.data.items[0];
  }

  const secondarySearch = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': lang,
    'fields.countryCodes': 'ALL',
  });


  const secondaryCall = await axios.get(`${API_URL}/entries?${secondarySearch.toString()}`);
  if (secondaryCall.data.items[0]) {
    return secondaryCall.data.items[0];
  }

  const lastCallQuery = new URLSearchParams({
    access_token: accessToken,
    content_type: contentType,
    'fields.slug': pageSlug,
    'fields.languageCode[match]': 'en',
  });

  const lastCall = await axios.get(`${API_URL}/entries?${lastCallQuery.toString()}`);
  if (lastCall.data.items[0]) {
    return lastCall.data.items[0];
  }

  console.error("Data Not Found, For => ", [primaryCall, secondaryCall, lastCall]);
  return data;
}

//pooja, 10-july-2023, login url for country
export async function getCountryLoginUrl(countryCode, langCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson['sched-login-url-'+langCode]) return data.fields.resJson['sched-login-url-'+langCode];
  return ""
}
export async function getCountryAcsLoginUrl(countryCode, langCode) {
  const data = await getCountryResourceGroup(countryCode);
  if (data?.fields?.resJson['login-url1-'+langCode]) return data.fields.resJson['login-url1-'+langCode];
  return ""
}

