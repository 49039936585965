<template>
  <div class="collapse-container" :class="data.fields.styleInstructions">
    <div class="padding-bottom-2 padding-top-3 text-right">
      <button
        type="button"
        @click="toggle()"
        class="usa-button usa-button--unstyled text-black text-bold text-no-underline hover:text-black cgi-toggle-all"
        data-scope="#toggle-group-2"
        :class="{
          'cgi-toggle-all--expanded': expandAll,
          'cgi-toggle-all': !expandAll,
        }"
      >
        <span class="cgi-toggle-all__is-collapsed">
          <span class="svg-1em">
            <svg width="24" height="24" viewBox="0 0 512 512" role="img" focusable="false" aria-labelledby="svgExpandTitle" aria-describedby="svgExpandDescription">
              <title id="svgExpandTitle">Expand all</title>
              <desc id="svgExpandDescription">Expand all</desc>
              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"></path>
            </svg>
          </span>
          <span class="padding-left-05">{{ translationKeywords['expand-all'] || 'Expand all' }}</span>
        </span>
        <span class="cgi-toggle-all__is-expanded">
          <span class="svg-1em">
            <svg width="24" height="24" viewBox="0 0 512 512" role="img" focusable="false" aria-labelledby="svgCollapseTitle" aria-describedby="svgCollapseDescription">
              <title id="svgCollapseTitle">Collapse all</title>
              <desc id="svgCollapseDescription">Collapse all</desc>
              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"></path>
            </svg>
          </span>
          <span class="padding-left-05">{{ translationKeywords['collapse-all'] || 'Collapse all' }}</span>
        </span>
      </button>
    </div>
    <div :key="i" v-for="(step, i) of data.fields.collapsableCardReferences">
      <div :key="j" v-for="(step2, j) of step">
        <document-renderer
          :documentId="step2.id"
          :additionaldata="{ encapsulator: encapsulator }"
        ></document-renderer>
      </div>
    </div>
  </div>
</template>

<script>
import { getTranslationKeywords } from "@/store/api";
import { urlLangCode } from "@/helpers/url";

export default {
  name: "collapsable-encapsulator",
  props: ["data"],
  data() {
    return {
      expandAll: false,
      encapsulator: this,
      translationKeywords: {},
    };
  },
  methods: {
    toggle() {
      if (this.expandAll) {
        this.expandAll = false;
        this.$emit("collapse-all");
      } else {
        this.expandAll = true;
        this.$emit("expand-all");
      }
    },
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  },
};
</script>
 
<style scoped>
.medium-center {
  width: 100%;
  margin: 0 auto;
}
.collapse-container {
  margin-bottom: 50px;
}
</style>
