<template>
  <div class="row page-horizontal-component" :class="data.fields.sectionStyleInstruction">
    <div class="container">
      <h2>{{ data.fields.sectionHeading }}</h2>
      <div v-if="$route.path === '/'">
        <ul class="custom-letters letter-links margin-y-4 text-center usa-list--unstyled">
          <li class="display-inline" data-id="A" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with A">A</a>
          </li>
          <li class="display-inline" data-id="B" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with B">B</a>
          </li>
          <li class="display-inline" data-id="C" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with C">C</a>
          </li>
          <li class="display-inline" data-id="D" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with D">D</a>
          </li>
          <li class="display-inline" data-id="E" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with E">E</a>
          </li>
          <li class="display-inline" data-id="F" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with F">F</a>
          </li>
          <li class="display-inline" data-id="G" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with G">G</a>
          </li>
          <li class="display-inline" data-id="H" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with H">H</a>
          </li>
          <li class="display-inline" data-id="I" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with I">I</a>
          </li>
          <li class="display-inline" data-id="J" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with J">J</a>
          </li>
          <li class="display-inline" data-id="K" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with K">K</a>
          </li>
          <li class="display-inline" data-id="L" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with L">L</a>
          </li>
          <li class="display-inline" data-id="M" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with M">M</a>
          </li>
          <li class="display-inline" data-id="N" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with N">N</a>
          </li>
          <li class="display-inline" data-id="O" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with O">O</a>
          </li>
          <li class="display-inline" data-id="P" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with P">P</a>
          </li>
          <li class="display-inline" data-id="Q" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with Q">Q</a>
          </li>
          <li class="display-inline" data-id="R" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with R">R</a>
          </li>
          <li class="display-inline" data-id="S" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with S">S</a>
          </li>
          <li class="display-inline" data-id="T" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with T">T</a>
          </li>
          <li class="display-inline" data-id="U" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with U">U</a>
          </li>
          <li class="display-inline" data-id="V" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with V">V</a>
          </li>
          <li class="display-inline" data-id="Y" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with Y">Y</a>
          </li>
          <li class="display-inline" data-id="Z" @click="toggle($event)">
            <a class="letter-link usa-button usa-button--outline" aria-label="Show countries that begin with Z">Z</a>
          </li>
        </ul>
      </div>
      <rich-text-renderer :data="data.fields.sectionBody"></rich-text-renderer>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-horizontal-component",
  props: ["data","additionaldata"],
  data() {
    return {
      showSection: false,
    };
  },
  created() {
    console.log('this.additionaldata',this.additionaldata)
  },
  methods:{
    toggle(event) {
      var dataId = event.currentTarget.getAttribute('data-id');

      const Headeritem = this.$el.querySelectorAll('.usa-accordion__button');
      Array.prototype.forEach.call(Headeritem, header => {
        const getCtrlAttr = header.getAttribute('aria-controls');
        if(dataId == getCtrlAttr) {
          header.classList.toggle('active');
          header.scrollIntoView();
        }
      });

      const item = this.$el.querySelectorAll('.usa-accordion__content');
      Array.prototype.forEach.call(item, child => {
        if(dataId == child.id) {
          child.classList.toggle('active');
          // child.scrollIntoView();
        }
      });
    },
  },
  watch: {
    $route: function() {
      if (this.$route.path === "/en/landing-page/" ||  '' || '/') {
        this.showSection = true;
      } else  {
        this.showSection = false;
      }
    }
  }
};

</script>
<style lang="scss" scoped>
.odd {
  .page-horizontal-component {
    background-color: #fff;
  }
}

.page-horizontal-component {
  display: flex;
  background-color: #f0f0f0;

  &.white-bg {
    background-color: #FFFFFF;
  }

  &.first-section {
    .container {
      background-color: white;
      top: -3rem;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    }

    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: 2.12941rem;
    }
  }

  &.all-center-aligned,
  &.all-center-align {
    text-align: center;

    h2 {
      margin-bottom: 1em;
      font-size: 2.12941rem;
    }
  }

  &.heading-center-align h2 {
    text-align: center;
    font-size: 2.12941rem;
  }

  &.small-heading h2 {
    font-size: 1.5em;
  }

  .container {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .visa-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  p {
    font-size: 1.06471rem;
    line-height: 1.52155;
  }
}

.collapsible-encapsulator-width {
  .container {
    padding-left: 12em;
    padding-right: 12em;
  }
}

ul {
  margin-top: 1em;
  line-height: 1.52155;
  padding-left: 3ch;

  li {
    margin-bottom: .25em;

    p {
      margin: 0;
    }
  }
}

.general-information {
  .first-section {
    .container {
      h2 {
        font-size: 2.12941rem;
      }
    }
  }
}

.para-left-align {

  h1,
  h3 {
    text-align: center;
  }
}

.general-information {
  #helpful-links {
    h1 {
      margin-bottom: 1em;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-horizontal-component {
    .container {
      padding: 2rem 1.8rem;
    }

    &.first-section {
      .container {
        width: 93.4%;

        ::v-deep .visa-types {
          .type-block {
            width: 100%;
          }
        }
      }
    }
  }
}
.custom-letters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-top: 2rem;
}

</style>
