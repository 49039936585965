import AssetComponent from '@/components/AssetComponent'
import PageHorizontalComponent from "@/components/PageHorizontalComponent";
import VisaTypeCardEncapsulator from "@/components/VisaTypeCardEncapsulator";
import VisaTypeBlock from "@/components/VisaTypeBlock";
import NoticeComponent from "@/components/NoticeComponent";
import HowToApply from "@/components/HowToApply";
import VisaStep from "@/components/VisaStep";
import StepIcon from "@/components/StepIcon";
import DocumentRenderer from '@/renderer/DocumentRenderer';
import RichTextRenderer from '@/renderer/RichTextRenderer';
import FooterWithLinks from "@/components/FooterWithLinks";
import FooterLeft from "@/components/FooterLeft";
import FooterMiddle from "@/components/FooterMiddle";
import FooterRight from "@/components/FooterRight";
import LinkComponent from "@/components/LinkComponent";
import VisaTypeFeeBlock from "@/components/VisaTypeFeeBlock";
import MoreBlock from "@/components/MoreBlock";
import ExchangeRateInfoSection from "@/components/ExchangeRateInfoSection";
import Collapsible from "@/components/Collapsible";
import ColumnHorizontalSection from "@/components/ColumnHorizontalSection";
import SingleBlock from "@/components/SingleBlock";
import ButtonCta from "@/components/ButtonCta";
import Step6TrackPassportEmailSvg from "@/components/Step6TrackPassportEmailSvg";
import HeaderComponent from "@/components/Header";
import HeaderMenuItem from "@/components/HeaderMenuItem";
import HeaderMenuColumn from "@/components/HeaderMenuColumn";
import BlockOfText from "@/components/BlockOfText";
import VacCard from "@/components/VacCard";
import SubSection from "@/components/SubSection";
import ColumnSection from "@/components/ColumnSection";
import CollapsableEncapsulator from "@/components/CollapsableEncapsulator";
import PageLeftMenuNavItem from "@/components/PageLeftMenuNavItem";
import PageLeftSideMenu from "@/components/PageLeftSideMenu";
import NoticeVariationWithText from "@/components/NoticeVariationWithText";
import BasicTable from "@/components/BasicTable";
import Simple2ColumnSubSection from "@/components/Simple2ColumnSubSection";
import BasicComponent from "@/components/BasicComponent";
import IndependentCta from "@/components/IndependentCta";
import FullWidthNotice from "@/components/FullWidthNotice";
import SmallShadedCard from "@/components/SmallShadedCard";
import FaqSection from "@/components/FaqSection";
import FaqCard from "@/components/FaqCard";
import FaqEntry from "@/components/FaqEntry";
import HtmlRenderer from "@/components/HtmlRenderer";
import GroupAppointmentCal from "@/components/GroupAppointmentCal";
import ModalPopup from "@/components/ModalPopup";
import GoliveLandingPage from "@/components/GoliveLandingPage";

export const componentNameMapping = {
  [HeaderMenuColumn.name]: HeaderMenuColumn,
  [HeaderMenuItem.name]: HeaderMenuItem,
  [HeaderComponent.name]: HeaderComponent,
  [DocumentRenderer.name]: DocumentRenderer,
  [RichTextRenderer.name]: RichTextRenderer,
  [AssetComponent.name]: AssetComponent,
  [BasicComponent.name]: BasicComponent,
  [PageHorizontalComponent.name]: PageHorizontalComponent,
  [VisaTypeCardEncapsulator.name]: VisaTypeCardEncapsulator,
  [VisaTypeBlock.name]: VisaTypeBlock,
  [NoticeComponent.name]: NoticeComponent,
  [HowToApply.name]: HowToApply,
  [VisaStep.name]: VisaStep,
  [StepIcon.name]: StepIcon,
  [FooterWithLinks.name]: FooterWithLinks,
  [FooterLeft.name]: FooterLeft,
  [FooterMiddle.name]: FooterMiddle,
  [FooterRight.name]: FooterRight,
  [LinkComponent.name]: LinkComponent,
  [VisaTypeFeeBlock.name]: VisaTypeFeeBlock,
  [MoreBlock.name]: MoreBlock,
  [ExchangeRateInfoSection.name]: ExchangeRateInfoSection,
  [Collapsible.name]: Collapsible,
  [FooterRight.name]: FooterRight,
  [ColumnHorizontalSection.name]: ColumnHorizontalSection,
  [SingleBlock.name]: SingleBlock,
  [ButtonCta.name]: ButtonCta,
  [Step6TrackPassportEmailSvg.name]: Step6TrackPassportEmailSvg,
  [BlockOfText.name]: BlockOfText,
  [VacCard.name]: VacCard,
  [SubSection.name]: SubSection,
  [ColumnSection.name]: ColumnSection,
  [CollapsableEncapsulator.name]: CollapsableEncapsulator,
  [PageLeftMenuNavItem.name]: PageLeftMenuNavItem,
  [PageLeftSideMenu.name]: PageLeftSideMenu,
  [NoticeVariationWithText.name]: NoticeVariationWithText,
  [BasicTable.name]: BasicTable,
  [Simple2ColumnSubSection.name]: Simple2ColumnSubSection,
  [IndependentCta.name]: IndependentCta,
  [FullWidthNotice.name]: FullWidthNotice,
  [SmallShadedCard.name]: SmallShadedCard,
  [FaqSection.name]: FaqSection,
  [FaqCard.name]: FaqCard,
  [FaqEntry.name]: FaqEntry,
  [HtmlRenderer.name]: HtmlRenderer,
  [GroupAppointmentCal.name]: GroupAppointmentCal,
  [ModalPopup.name]: ModalPopup,
  [GoliveLandingPage.name]: GoliveLandingPage
};

export const modalComponentNameMapping = {
  BasicComponent: BasicComponent,
  headerMenuColumn: HeaderMenuColumn.name,
  headerMenuItem: HeaderMenuItem.name,
  headerMenu: HeaderComponent.name,
  footerRight: FooterRight.name,
  footerLeft: FooterLeft.name,
  footerMiddle: FooterMiddle.name,
  fullFooter: FooterWithLinks.name,
  visaStep: VisaStep.name,
  howToApplyStepsSection: HowToApply.name,
  notice: NoticeComponent.name,
  pageHorizontalSection: PageHorizontalComponent.name,
  Asset: AssetComponent.name,
  cardEncapsulator: VisaTypeCardEncapsulator.name,
  visaTypeShort: VisaTypeBlock.name,
  visaTypeFeeCard: VisaTypeFeeBlock.name,
  shadedCardWithHeading: MoreBlock.name,
  exchangeRateInfoSection: ExchangeRateInfoSection.name,
  smallShadedCard: SingleBlock.name,
  collapsableSection: Collapsible.name,
  page2ColumnHorizontalSection: ColumnHorizontalSection.name,
  buttonCta: ButtonCta.name,
  step6TrackPassportEmailSvg: Step6TrackPassportEmailSvg.name,
  address2ColumnSection: ColumnSection.name,
  blockOfText: BlockOfText.name,
  vacCard: VacCard.name,
  subSection: SubSection.name,
  collapsableEncapsulator: CollapsableEncapsulator.name,
  pageLeftMenuNavItem: PageLeftMenuNavItem.name,
  pageLeftSideMenu: PageLeftSideMenu.name,
  noticeVariationWithText: NoticeVariationWithText.name,
  basicTable: BasicTable.name,
  simple2ColumnSubSection: Simple2ColumnSubSection.name,
  independentCta: IndependentCta.name,
  fullWidthNotice: FullWidthNotice.name,
  faqSection: FaqSection.name,
  iconCard: FaqCard.name,
  faqEntry: FaqEntry.name,
  groupAppointmentCalc: GroupAppointmentCal.name,
  modalPopUp: ModalPopup.name,
  goliveLandingPage: GoliveLandingPage.name
};
