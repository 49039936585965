<template>
  <div class="cgi-card height-auto padding-2">
    <div :hidden="!showModal">
      <document-renderer
        :key="i"
        v-for="(gp, i) of data.fields.modalPopupRefs"
        :documentId="gp.sys.id"
        :additionaldata="{
          calculator: calculator,
          showModal: showModal,
          currency: currency,
          total: total,
          validDate: validDate,
          visaType: visaType,
          tier:tier,
          tierCode:tierCode,
          tiergrpCode:tiergrpCode,
          quantity:quantity,
          usdamt: usdamt,
          tiergroupcode: tiergroupcode,
        }"
      ></document-renderer>
    </div>
    <form
      @submit.prevent="onSubmit()"
      class="usa-form"
      id="group-fees"
      style="max-width: none"
      v-if="visaTypes"
    >
      <fieldset class="usa-fieldset">
        <legend class="usa-legend font-sans-lg">{{ translationKeywords['group-fees-calculator'] || 'Group fees calculator' }}</legend>
        <label class="usa-label" for="visa-type">{{ translationKeywords['visa-type'] || 'Visa type' }}</label>
        <select
          v-model="visaType"
          class="usa-select"
          id="visa-type"
          v-on:change="changeItem(visaType, $event)"
        >
          <option value="">{{translationKeywords['select-visa-type'] || 'Select visa type'}}</option>
          <option :value="type['type-code'] + ':' + type.fee+ ':' + type['tier'] + ':' + type['tier-code']" :key="i" v-for="(type, i) of visaTypes" v-if="!data.fields.blacklist.includes(type['type-code'])" >
            {{ type["type-code"] }}: {{ type["type-name"] }} ${{ type.fee }} USD
          </option>
        </select>
        <div class="grid-row">
          <div class="tablet:grid-col-3">
            <label class="usa-label" for="quantity">{{translationKeywords['quantity'] || 'Quantity'}}</label>
            <input
              v-model="quantity"
              class="usa-input maxw-none"
              id="quantity"
              type="number"
              :min="groupQtyMin"
              :max="groupQtyMax"
              autocomplete=""
            />
            <button
              class="usa-button margin-top-3"
              type="submit"
              style="width: 100%"
            >
            {{translationKeywords['calculate-fees'] || 'Calculate fees'}}
            </button>
          </div>
        </div>
        <div class="margin-top-4" v-if="errorMsg">
          <span class="usa-error-message">{{translationKeywords['visa-type-must-be-selected'] || 'Visa type must be selected'}}</span>
        </div>
        <output class="display-block" v-if="hasValue && total && output">
          <div class="margin-top-4">
            <label class="usa-label" id="total-label" v-if="amtdisplay != 'N'">{{translationKeywords['total'] || 'Total'}}</label>
            <h3 class="margin-top-1" aria-labelledby="total-label" v-if="amtdisplay != 'N'">
              {{ currency }} {{ total }}
            </h3>
            <span v-if="!pgLink || pgLink.length == 0">
              <button
                type="button"
                class="usa-button usa-dialog__open margin-top-3"
                aria-controls="group-fees-dialog"
                :aria-expanded="showModal ? 'true' : 'false'"
                aria-haspopup="true"
                @click="showModal = true"
              >
              {{translationKeywords['view-payment-of-visa-fees'] || 'View payment of visa fees'}}
              </button>
            </span>
            <!-- Avnish, 13-July-2023, Requirement- add multiple payment link dynamically -->
            <span v-if="pgLink && pgLink.length > 0">
              <a :href="item['value']" target="_blank" v-for="(item, index) in pgLink">
                <button
                  class="usa-button margin-top-3"
                  type="button"
                  style="width: 30%"
                >
                  {{translationKeywords[item['name']] || item['name']}}
                </button>
              </a>
            </span>
          </div>
        </output>
      </fieldset>
    </form>
  </div>
</template>
<script>
import {
  getVisaTypes,
  getExchangeRate,
  getCurrencyCode,
  getGroupMinQty,
  getGroupMaxQty,
  getTranslationKeywords,
  getExchangeRateExpiry,
  // Avnish, 13-July-2023, Requirement- add multiple payment link dynamically
  getCountryResourceGroup,
  getLanguageSpecificResources
} from "@/store/api";
import { urlLangCode, urlCountryCode } from "@/helpers/url";

function onSubmit() {
  if (!this.visaType) {
    this.errorMsg = true;
  } else {
    this.errorMsg = false;
  }
  this.output = true;

  if (this.hasValue) {
    this.total = (this.quantity * ((this.visaType.split(":")[1] * this.exchangeRate))).toFixed(2);
    this.usdamt= (this.quantity * ((this.visaType.split(":")[1]))).toFixed(2);

  }
  // Avnish, 13-July-2023, Requirement- add multiple payment link dynamically
  let country_code = urlCountryCode();
  let language_code = urlLangCode();
  getLanguageSpecificResources(language_code).then(langRes => {
    if(langRes?.fields?.resJson) {
      let langResJson = langRes?.fields?.resJson;
      let visaClass = this.visaType.split(":")[0];
      let visaTypeDetails = langResJson['visa-types'].filter(value => {
        return (value['type-code'] == visaClass)? value:null;
      });
      let codeValue = "";
      let tiergroupcode = "";
      console.log("visaTypeDetails::",visaTypeDetails);
      if(visaTypeDetails && visaTypeDetails.length >= 0) {
        codeValue = visaTypeDetails[0]['code-value'];
        this.tiergroupcode = visaTypeDetails[0]['tier-group-code'];
        console.log("Grp -",this.tiergroupcode);

      }
      getCountryResourceGroup(country_code).then(countryRes => {
        if(countryRes.fields?.resJson) {
          let resJson = countryRes.fields?.resJson;
          if(resJson['amtdisplay']) {
             this.amtdisplay = resJson['amtdisplay'];
           }
          if(resJson['country-pg-url']) {
            this.pgLink = [];
            let pgUrlKeyArr = Object.keys(resJson['country-pg-url']);
            for (let index = 0; index < pgUrlKeyArr.length; index++) {
              let pgUrlKey = pgUrlKeyArr[index];
              let pgUrlValue = resJson['country-pg-url'][pgUrlKey];
              let countryName = resJson['country'];
              
              if(countryName) {
                pgUrlValue = pgUrlValue + "?country_name="+countryName;
              }
              if(this.quantity) {
                pgUrlValue = pgUrlValue + "&quantity="+this.quantity;
              }
              if(codeValue) {
                pgUrlValue = pgUrlValue + "&code="+codeValue;
              }
              this.pgLink.push({"name": pgUrlKey, "value": pgUrlValue});
            }
          }
        }
      });
    }
  });
}

export default {
  name: "group-appointment-cal",
  props: ["data"],
  data(){
    return{
      currency: '',
      translationKeywords: {},
      // data:{'fields':{'blacklist':[]}}
    }
  },
  methods: {
    onSubmit,
    changeItem: function changeItem(visaType, event) {
      this.output = true;
      if (this.visaType === "") {
        this.errorMsg = true;
      } else {
        this.errorMsg = false;
        this.output = false;
        this.tier = this.visaType.tier ;
        this.tierCode = this.visaType['tier-code'];
      }
    },
  },
  data() {
    return {
      showModal: false,
      calculator: this,
      currency: null,
      quantity: 0,
      visaType: "",
      total: 0,
      usdamt: 0,
      errorMsg: false,
      output: false,
      visaTypes: null,
      exchangeRate: null,
      groupQtyMin: null,
      groupQtyMax: null,
      validDate: '',
      tier:'',
      tierCode:'',
      // Avnish, 13-July-2023, Requirement- add multiple payment link dynamically
      pgLink: [],
        amtdisplay: 'Y'
    };
  },
  computed: {
    hasValue() {
      return this.quantity && this.visaType;
    },
  },
  watch: {
    quantity: function (val) {
      const qty = Number(val);
      if (this.groupQtyMin && qty < this.groupQtyMin) {
        this.quantity = this.groupQtyMin;
      }
      if (this.groupQtyMax && qty > this.groupQtyMax) {
        this.quantity = this.groupQtyMax;
      }
    },
    
  },
  created() {
    if(!this.data.fields.blacklist)  {this.data.fields['blacklist']=[];}
    this.$on("close-modal", () => {
      this.showModal = false;
    });
    const countryCode = urlCountryCode();
    const langCode = urlLangCode();
    console.log('langCode test deployment =>',langCode)
    getCurrencyCode(countryCode).then((currency) => (this.currency = currency));
    getExchangeRate(countryCode).then(
      (exchangeRate) => (this.exchangeRate = exchangeRate)
    );
    getVisaTypes(langCode).then((visaTypes) => (this.visaTypes = visaTypes));
    getGroupMinQty(countryCode).then((quantity) => {
      this.groupQtyMin = quantity;
      this.quantity = quantity;
    });
    getGroupMaxQty(countryCode).then((quantity) => {
      this.groupQtyMax = quantity;
    });
    getExchangeRateExpiry(countryCode).then(expiryDate => { 
      this.validDate = expiryDate;
    });
    
    this.$forceUpdate();
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  },
};
</script>
<style lang="scss" scoped></style>
