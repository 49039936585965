<template>
    <li class="usa-sidenav__item"  v-if="data" :class="limatched"> 
        <a :aria-describedby="hrefmatched" :href="data.fields.menuItemTargetLink">{{ data.fields.menuItemText }}</a>
    </li>
</template>
      
<script>
import { urlSlug } from "@/helpers/url";

export default {
    name: "side-left-menu-item",
    props: ["data"],
    computed: {
        limatched(){
            const urlSlugText = urlSlug();
            const pathParts = location.pathname.split('/');
            //console.log("text"+ urlSlugText+ "Left menu:"+pathParts+"value:"+pathParts[pathParts.length - 1]);
            //let pears = this.data.fields.menuItemTargetLink.includes(pathParts[pathParts.length - 1])
            // modified to accomodate the last / in url
            let pears = this.data.fields.menuItemTargetLink.includes(urlSlugText);
            if (pears && urlSlugText != 'terms-and-conditions') return 'usa-current';
            return '';
         },
          hrefmatched(){                          
            const urlSlugText = urlSlug();
            const pathParts = location.pathname.split('/');
            let pears = this.data.fields.menuItemTargetLink.includes(pathParts[pathParts.length - 1])
            if (pears && urlSlugText != 'terms-and-conditions') return 'describedby-selected';
            return '';
         }
    }
}
</script>
