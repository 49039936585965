import { get } from "lodash";

export function pageSlug(pageData) {
  return pageData && pageData.fields && pageData.fields.pageSlug;
}

export function idSlug(data) {
  const case1 = get(data, "fields.sectionSlug");
  if (case1) return case1;

  const case2 = get(data, "fields.cardSlug");
  if (case2) return case2;

  const case3 = get(data, "fields.slug");
  if (case3) return case3;

  const case4 = get(data, "fields.pageSlug");
  if (case4) return case4;

  return "";

}

