
<template>
  <div v-if = "!this.$route.name.includes('depositslip') && !this.$route.name.includes('landing')"  class="grid-container usa-footer__return-to-top">
    <a class="usa-link" :href="urlSlugText" :class="home">{{translationKeywords['return-to-top'] || 'Return to top'}}</a>
    <a class="usa-link" :href="this.$route.path+'#top'" :class="landing">{{translationKeywords['return-to-top'] || 'Return to top'}}</a>
    <a v-if = "!isCustomizePage"
      class="usa-link" 
      href="https://ustraveldocs.com/"
      :class="homeonly"
      >
      {{translationKeywords['choose-country'] || 'Back to choose your country or region'}}
    </a>



</div>

</template>

<script>

import { getCurrencyCode,getTranslationKeywords } from "@/store/api";
import { urlCountryCode,urlSlug,urlLangCode } from "@/helpers/url";


export default {
  name: "back-to-top",
  data(){
    return{
      currency: '',
      translationKeywords: {},
    }
  },
  computed: {
    home() {
      var urlSlugText = urlSlug();
      this.urlSlugText = urlSlugText + "#top";
      if (urlSlugText == "") return "none";
      else return "";
    },
    homeonly() {
      var urlSlugText = urlSlug();
      this.urlSlugText = urlSlugText + "#top";
      if (urlSlugText == "" && (this.$route.path !== '/en/landing-page' || '')) return "";
      else return "none";
    },
    landing() {
      var urlSlugText = urlSlug();
      this.urlSlugText = urlSlugText + "#top";
      if (urlSlugText == "" && (this.$route.path === '/en/landing-page' || '')) return "";
      else return "none";
    },
  },
  watch: {
  $route (to, from){
      if(this.$route.path == '/') {
       this.isCustomizePage = true;
     } else {
       this.isCustomizePage = false;
     }
  }
  },
   beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords=keywords)
    );
  },
};
</script>

<style scoped>
.none {
  display: none;
}
.grid-container svg {
  color: #0071bc;
  height: 1em;
  margin-bottom: -3px;
  width: 1em;
}
</style>