<template>
  <div class="emailbox">
    <div class="emailbox__image">
      <document-renderer
        :documentId="data.fields.trackPassportImage.sys.id"
        :documentType="data.fields.trackPassportImage.sys.linkType"
      ></document-renderer>
    </div>
    <svg class="emailbox__svg">
      <g class="emailbox__tall">
        <path d="M 220 82 l 30 0 l 0 -30" fill="none"></path>
        <path d="M 125 112 l 80 0 l 0 37" fill="none"></path>
        <path d="M 125 140 l 80 0 l 0 100" fill="none"></path>
        <circle cx="220" cy="82" r="4"></circle>
        <circle cx="125" cy="112" r="4"></circle>
        <circle cx="125" cy="140" r="4"></circle>
      </g>
      <g class="emailbox__wide">
        <path d="M 282 52 l 30 0 l 0 -30" fill="none"></path>
        <path d="M 155 92 l 40 0 l 0 37" fill="none"></path>
        <path d="M 155 129 l 150 0" fill="none"></path>
        <circle cx="282" cy="52" r="4"></circle>
        <circle cx="155" cy="92" r="4"></circle>
        <circle cx="155" cy="129" r="4"></circle>
      </g>
    </svg>
    <div class="emailbox__text emailbox__text--top">
      <rich-text-renderer :data="data.fields.toEmailText"></rich-text-renderer>
    </div>
    <div class="emailbox__text emailbox__text--bottom">
      <rich-text-renderer :data="data.fields.subjectAndBodyText"></rich-text-renderer>
    </div>
  </div>
</template>
<script>
export default {
  name: "step6-track-passport-email-svg",
  props: ["data"],
};

</script>
<style lang="scss" scoped>
</style>
