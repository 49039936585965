<template>
  <div>
    <button
      type="button"
      class="usa-accordion__button usa-nav__link"
      :aria-expanded="nonMegaMenu ? 'true' : 'false'"
      :aria-controls="data.fields.menuComponentHeading"
      @click="onButtonClick()"
    >
      <span>{{ data.fields.menuComponentHeading }}</span>

      <span class="mobile-only">
        <span class="icon" v-if="nonMegaMenu">
          <img src="/assets/img/minus-alt.svg" alt="collaps" width="12" height="15" />
        </span>
        <span class="icon" v-else-if="!nonMegaMenu">
          <img src="/assets/img/plus-alt.svg" alt="expand" width="12" height="15" /> 
        </span>
      </span>
    </button>
    <div
      class="usa-nav__submenu usa-megamenu"
      :class="{ 'show-non': nonMegaMenu }" :id="data.fields.menuComponentHeading"
    >
      <rich-text-renderer
        :data="data.fields.menuComponentBody"
      ></rich-text-renderer>
    </div>
  </div>
</template>

<script>
function outsideMenuClickHandler(event) {
  const isButtonClicked = event.target.closest("button.usa-accordion__button"); 
  const isMegaMenuClicked = event.target.closest("div.usa-nav__submenu.usa-megamenu"); 
  if (!isButtonClicked && !isMegaMenuClicked) {
    this.nonMegaMenu = false;
  }
}

function onNavOpen(nav) {
  if (nav !== this.data.fields.menuComponentHeading) {
    this.nonMegaMenu = false;
  }
}

function onButtonClick() {
  const root = this.$root; 
  this.nonMegaMenu = !this.nonMegaMenu;
  root.$emit("nav-open", this.data.fields.menuComponentHeading);
}

export default {
  name: "header-menu-item",
  props: ["data"],
  data: () => ({
    nonMegaMenu: false,
  }),
  methods: {
    outsideMenuClickHandler: outsideMenuClickHandler,
    onNavOpen: onNavOpen,
    onButtonClick: onButtonClick,
  },
  created() {
    document.addEventListener("click", this.outsideMenuClickHandler);
    this.$root.$on("nav-open", this.onNavOpen);
  },
  destroyed() {
    document.removeEventListener("click", this.outsideMenuClickHandler);
    this.$root.$off("nav-open", this.onNavOpen);
  },
};
</script>

<style lang="scss" scoped>
.usa-megamenu {
  display: none;
  .rich-text {
    display: flex;
    justify-content: space-between;
    ::v-deep a {
      color: white;
    }
  }
  &:before,
  &:after {
    margin: 0 -1px;
  }
  & > .rich-text {
    ::v-deep > *:not(li) {
      display: none;
    }
  }
}
.show-non {
  display: block;
}

.none {
  display: none;
}
.block {
  display: block;
}
.mobile-only {
  display: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .usa-megamenu {
    .rich-text {
      display: block;
      ::v-deep a {
        color: #565c65;
        border-bottom: 1px solid #dcdee0;
        padding: 5px 5px 5px 25px;
      }
      ::v-deep p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .usa-nav__primary {
    button {
      background: none;
    }
  }
  .mobile-only {
    display: block;
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .usa-megamenu {
    .rich-text {
      display: block;
      ::v-deep a {
        color: #565c65;
        border-bottom: 1px solid #dcdee0;
        padding: 5px 5px 5px 25px;
      }
      ::v-deep p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .usa-nav__primary {
    button {
      background: none;
    }
  }
  .mobile-only {
    display: block;
    float: right;
  }
}
</style>
