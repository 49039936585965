<template>
  <div v-if = "!this.$route.name.includes('depositslip')" :class="data.fields.styleInstructions">
    <div class="footer-links-holder" v-bind:class="{ active: footerToggle }">
      <div class="footer-heading" @click="toggleFooter()">
        <rich-text-renderer
        :data="data.fields.heading"></rich-text-renderer>
      </div>
      <rich-text-renderer
        :data="data.fields.details"
        class="footer-links" 
      ></rich-text-renderer>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-left",
  props: ["data"],
  data() {
    return {
      footerToggle: false,
      id: Math.random()
    };
  },
  created() {
    this.$root.$on("footer:expand", (id) => {
      if (id !== this.id) {
        this.footerToggle = false;
        return;
      }
      this.footerToggle = !this.footerToggle;
    });
  },
  methods: {
    toggleFooter(e) {
      this.$root.$emit("footer:expand", this.id);
    },
  },
};
</script>
<style lang="scss" scoped>
  .for-mobile {
    display: none;
  }
  .for-desktop {
    display: block;
  }
  .footer-heading {
    ::v-deep .rich-text {
        h3 {
        color: #FFF;
        margin: 0 0 10px;
        font-size: 1.3rem;
      }
    }
  }
  .footer-links-holder {
    padding: 0 30px 0 0px;
  }
  @media (min-width: 320px) {
    .for-mobile {
      display: block;
    }
    .for-desktop {
      display: none;
    }
  }

  @media (min-width: 480px) {
    .for-mobile {
      display: none;
    }
    .for-desktop {
      display: block;
      width: 33.33%;
    }
  }
</style>
