<template>
  <div class="grid-container line-height-sans-5" :class="data.fields.sectionStyleInstruction">
    <div :class="getParentClass">
      <div :class="getFirstClass">
        <h2 class="text-center" id="uscis-fee">
          {{ data.fields.column1Heading }}
        </h2>
        <rich-text-renderer
          :data="data.fields.sectionColumn1Body"
        ></rich-text-renderer>
      </div>
      <div  :class="getSecondClass">
        <h2 class="text-center" id="medical-requirements">
          {{ data.fields.column2Heading }}
        </h2>
        <rich-text-renderer
          :data="data.fields.sectionColumn2Body"
        ></rich-text-renderer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "simple2-column-sub-section",
  props: ["data"],
  computed: {
    getFirstClass () {
      return this.data.fields.sectionStyleInstruction === 'heading:bold left-align no-margin all-columns:30-70' ? ' grid-col-12 tablet:grid-col-4 margin-bottom-1 tablet:margin-bottom-0' : 'grid-col-12 margin-bottom-4 tablet:grid-col-6'
    },
     getSecondClass () {
      return this.data.fields.sectionStyleInstruction === 'heading:bold left-align no-margin all-columns:30-70' ? ' grid-col-12 tablet:grid-col-7 tablet:grid-offset-1' : 'grid-col-12 margin-bottom-4 tablet:grid-col-6'
    },
    getParentClass () {
      return this.data.fields.sectionStyleInstruction === 'heading:bold left-align no-margin all-columns:30-70' ? ' margin-x-2 grid-row grid-gap-3 margin-y-3' : 'grid-row grid-gap-3'
    }
  }
};
</script>

<style lang="scss" scoped>
.no-margin {
  ::v-deep  p {
    margin: 0;
  }
}
#contact-us-hrs-ops-col,
#contact-us-hrs-ops-callers-from-us {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>