<template>

  <div>

    <div class="usa-dialog-overlay is-visible" aria-hidden="true" @click="onCloseModal()"></div>

    <div

      class="usa-dialog shadow-3 is-visible"

      id="group-fees-dialog" role="dialog" aria-modal="true" aria-labelledby="dialog-heading">

   

      <div class="usa-dialog__header">

        <div class="usa-dialog__header-controls">

          <button type="button" class="usa-dialog__close" @click="onCloseModal()" autofocus>

            <img src="/assets/img/close.svg" alt="close dialog" />

          </button>

        </div>

        <div class="usa-dialog__header-title" id="dialog-heading">

          <rich-text-renderer :data="data.fields.modalHeading"></rich-text-renderer>

        </div>

      </div>

      <div v-if="data.fields.modalBody!='' || data.fields.modalBody.length" id="template" class="usa-dialog__body usa-prose" style="display: none;">

        <rich-text-renderer :data="data.fields.modalBody"></rich-text-renderer>

      </div>

      <div v-else class="usa-dialog__body usa-prose">

        Note : We identified poor connection, please reload the page

      </div>

      <div

        v-if="template && additionaldata.showModal"

        id="body"

        class="usa-dialog__body usa-prose"

      >

        <RuntimeTemplateCompiler :template="template" />

      </div>

      <div class="usa-dialog__footer" v-if="data.fields.modalFooter">

        <rich-text-renderer :data="data.fields.modalFooter"></rich-text-renderer>

      </div>

    </div>

  </div>

</template>

<script>

import { RuntimeTemplateCompiler } from "vue-runtime-template-compiler";

// Avnish 23-June-23, romania group calculator changes for slip

import { urlCountryCode } from "@/helpers/url";

function onCloseModal() {

  this.additionaldata.calculator.$emit("close-modal");

  this.$root.$emit("modal-popup:hide");

}



function onComponentClick(event) {

  if (

    event.target.classList.contains("close-button") ||

    event.target.closest(".close-button")

  ) {

    this.onCloseModal();

  }

  if (

    event.target.classList.contains("print-button") ||

    event.target.closest(".print-button")

  ) {

    this.$htmlToPaper("group-fees-dialog");

  }

}



// Avnish 23-June-23, romania group calculator changes for slip

var receipt_number = "";

export default {

  name: "modal-popup",

  props: ["data", "additionaldata"],

  components: { RuntimeTemplateCompiler },

  data() {

    return {

      template: "",

      outputDisabled: true, // initial value

      visaType:"",

      uid:""

    };

  },

  created() {

    console.log(this.data,'Loaded');

    //pooja, 22-feb-23, shifted to common to get same receiptNumber for barcode also

    var randomrcptnumber=new Array();

      this.uid = "";

      for(var i = 0; i < 10; i++) {  

        randomrcptnumber[i]=Math.floor(Math.random()*10);  

        this.uid = this.uid + "" + randomrcptnumber[i];

      }

      // Avnish 23-June-23, romania group calculator changes for slip

      if(urlCountryCode() == "ro") {

        this.uid = "";

        for(var i = 0; i < 4; i++) {  

          randomrcptnumber[i]=Math.floor(Math.random()*10);  

          this.uid = this.uid + "" + randomrcptnumber[i];

        }

      }

  },

  computed: {

    visatypeNum(){

      this.visaType = this.additionaldata.visaType.split(":")[3];

    },

    outputDisabled() {

        setTimeout( () => {

            return true;

        }, 5000);

    },

    currencyCode() {

      return this.additionaldata.currency;

    },

    calculatedAmount() {

      if(urlCountryCode() == "jo")

      return parseFloat(this.additionaldata.total).toFixed(3);

      else 

      return this.additionaldata.total;

    },

    usdAmount() {

      return this.additionaldata.usdamt;

    },

    expiryDate() {

      return this.additionaldata.validDate;

    },

    tiergroupcode() {

      return this.additionaldata.tiergroupcode;

    },

    accountNumber(){

      var randomnumber=new Array();

      var uid = "";

      for(var i = 0; i < 10; i++) {  

        randomnumber[i]=Math.floor(Math.random()*10);  

        uid = uid + "" + randomnumber[i];

      }

      if (this.additionaldata.quantity > 1){

        return   'TIER5' + this.additionaldata.visaType.split(":")[3] + this.additionaldata.quantity + uid;

      }

      else{

        return   this.additionaldata.visaType.split(":")[2] + this.additionaldata.visaType.split(":")[3] + this.additionaldata.quantity + uid;



      

      }

    },

    receiptNumber(){

      //pooja, 22-feb-23, shifted to common to get same receiptNumber for barcode also

      // here evertime new receiptNumber generating and barcode not match 

      /* var randomrcptnumber=new Array();

        var uid = "";

        for(var i = 0; i < 10; i++) {  

          randomrcptnumber[i]=Math.floor(Math.random()*10);  

          uid = uid + "" + randomrcptnumber[i];

        }

        // Avnish 23-June-23, romania group calculator changes for slip

        if(urlCountryCode() == "ro") {

          uid = "";

          for(var i = 0; i < 4; i++) {  

            randomrcptnumber[i]=Math.floor(Math.random()*10);  

            uid = uid + "" + randomrcptnumber[i];

          }

        }

        

        if (this.additionaldata.quantity < 10){

          receipt_number = this.additionaldata.visaType.split(":")[3] + "0"+ this.additionaldata.quantity + uid;

        }

        else{

          receipt_number = this.additionaldata.visaType.split(":")[3] + this.additionaldata.quantity + uid;

        }

      */

      this.visatypeNum;

      if (this.additionaldata.quantity < 10){

        receipt_number = "0"+ this.additionaldata.quantity + this.uid;

      }

      else{

        receipt_number = this.additionaldata.quantity + this.uid;

      }

      return this.visaType +receipt_number;

    },

    NumberOfApplicants(){

      return this.additionaldata.quantity

    },

     //pooja 13-apr-23, barcode

     barcode(){

      var randomrcptnumber=new Array();

      var uid = "";

      for(var i = 0; i < 10; i++) {  

        randomrcptnumber[i]=Math.floor(Math.random()*10);  

        uid = uid + "" + randomrcptnumber[i];

      }

      var bar_code_number = "";

      var url = "https://cgi-barcodes.herokuapp.com/barcode/code128/a/";

      if (this.additionaldata.quantity < 10){

        var quantity = this.additionaldata.visaType.split(":")[3] + "0"+ this.additionaldata.quantity + uid;

        url = url+ quantity;

        bar_code_number = this.additionaldata.visaType.split(":")[3] + "0"+ this.additionaldata.quantity + uid;

      }

      else {

        var quantity = this.additionaldata.visaType.split(":")[3] + this.additionaldata.quantity + uid;

        url = url+ quantity;

        bar_code_number = this.additionaldata.visaType.split(":")[3] + this.additionaldata.quantity + uid; 

      }

      // Avnish 23-June-23, romania group calculator changes for slip

      if(urlCountryCode() == "ro") {

        var amt = this.additionaldata.total;

        var prefix = "";

        var localamount2 = ('' + amt ).replace(".", "");

        console.log("amt:" + localamount2 );



        var year = this.additionaldata.validDate.substr(0, 4);

        console.log("year:" + year);

        var mth = this.additionaldata.validDate.substr(5, 2);

        console.log("mth:", mth);

        var dt = this.additionaldata.validDate.substr(8, 10);

        var expiration_date2 = dt + mth +year;

        

        // change added for romania

        receipt_number = this.visaType+receipt_number;

        ////////////

        console.log("receipt:" + receipt_number );

        console.log("exp:" + expiration_date2 );



        var barcodeReferenceNumber = receipt_number + expiration_date2 + localamount2;

                console.log("barcoderef:" + barcodeReferenceNumber );



        if(barcodeReferenceNumber.length < 22) {

          var diff = 22 - parseInt(barcodeReferenceNumber.length);

          for (let i = 0; i < diff; i++) {

            prefix = prefix + "0";

          }

        }

        

        if(prefix.length > 0) {

          bar_code_number = receipt_number + expiration_date2 + prefix + localamount2;

        }   else {

          bar_code_number = receipt_number + expiration_date2 + localamount2;

        }

      }

      else if(urlCountryCode() == "jo") {
        receipt_number = this.visaType+receipt_number;
        var amt = parseFloat(this.additionaldata.total).toFixed(3);
        var prefix = "";
        if (amt < 1000)
            prefix = '00';
        else if (amt < 10000)
            prefix = '0';

        var bar_code_number = receipt_number + prefix + amt;

      }else if(urlCountryCode() == "vn") {

        if (this.additionaldata.quantity < 10){

          receipt_number = "0"+ this.additionaldata.quantity + this.uid;

        }

        else{

          receipt_number = this.additionaldata.quantity + this.uid;

        }

        bar_code_number = this.visaType +receipt_number;

      }

      var barcode = '<img src="'+url+'" ><br><div id="barcode"></div>';

      return "https://cgi-barcodes.herokuapp.com/barcode/code128/a/" + bar_code_number;

    },

    barcode1(){

      var bar_code_number = this.additionaldata.total;

      return "https://cgi-barcodes.herokuapp.com/barcode/code128/a/" + bar_code_number;

    },
    
    barcode2(){
    
      this.visatypeNum;

      if (this.additionaldata.quantity < 10){

        receipt_number = "0"+ this.additionaldata.quantity + this.uid;

      }

      else{

        receipt_number = this.additionaldata.quantity + this.uid;

      }

      return  "https://cgi-barcodes.herokuapp.com/barcode/code128/a/" + this.visaType +receipt_number;

    }

  },

  mounted() {

    this.$el.addEventListener("click", onComponentClick.bind(this));

    this.$el.querySelector('.usa-dialog__close').focus();

    

    document.addEventListener("keydown", (e) => {

      if (e.keyCode == 27) {

        this.additionaldata.calculator.$emit("close-modal");

      }

    });



    this.$root.$on("document-renderer:data-loaded", () => {

      this.template = this.$el.querySelector("#template").innerHTML;

    });

  },

  methods: {

    onCloseModal: onCloseModal,

  },

};

</script>

<style lang="scss" scoped>

::v-deep .usa-prose {

  h2 {

    margin-top: 0 !important;

    margin-bottom: 10px;

  }

}

::v-deep .usa-dialog__header-title {

  h1 {

    font-family: Helvetica,Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif !important;

    font-size: 1.8rem !important;

  }

}

::v-deep .usa-dialog__body {

   h3 {

    margin: 0 !important;

    padding: 0 !important;

  }

  h2 {

    font-size: 1.1rem;

  }

  p {

    margin: 0 0 10px !important;

    font-family: Helvetica,Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;

    font-size: 1rem;

  }

}

::v-deep .usa-dialog{

  font-family: Helvetica,Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;

  font-size: 1.06471rem;

}
</style>