<template>
  <component
    v-if="data"
    :is="component"
    :additionaldata="additionaldata"
    v-bind="{ data: data }"
    :id="id"
    :data-component-name="component"
  ></component>
</template>

<script>
import { getComponent } from "@/renderer/GlobalComponents";
import {
  getDataByDocumentId,
  getAssetData,
  getDataByParams,
} from "@/store/api";
import { get } from "lodash";
import { idSlug } from "@/helpers/data";

function getDocId($vnode) {
  let docId = get($vnode, "data.documentId");
  if (docId) {
    return docId;
  }
  docId = get($vnode, "data.attrs.documentId");
  if (docId) {
    return docId;
  }
  docId = get($vnode, "data.attrs.sys.id");
  if (docId) {
    return docId;
  }
  console.error("DOCUMENT RENDERER: documentId not found for => ", $vnode);
}

function getType($vnode) {
  let type = get($vnode, "data.documentType");
  if (type) return type;
  type = get($vnode, "data.attrs.documentType");
  if (type) return type;
}

function loadData(docId, type) {
  const onError = (err) => {
    console.error(
      "DOCUMENT RENDERER: data loading error for document id => ",
      docId,
      err
    );
  };

  const onAssetSuccess = (data) => {
    this.data = data;
    this.id = idSlug(data);
    this.component = getComponent("Asset", { docId, type });
    this.$root.$emit("document-renderer:data-loaded");
  };

  const onDocSuccess = (data) => {
    this.data = data;
    this.id = idSlug(data);
    this.component = getComponent(data.sys.contentType.sys.id, {
      docId,
      type,
    });
    this.$root.$emit("document-renderer:data-loaded");
  };

  if (type === "Asset") {
    return getAssetData(docId).then(onAssetSuccess).catch(onError);
  }
  if (this.countryCode && this.langCode && this.contentType && this.slug) {
    return getDataByParams(
      this.contentType,
      this.slug,
      this.countryCode,
      this.langCode
    )
      .then(onDocSuccess)
      .catch(onError);
  }

  return getDataByDocumentId(docId).then(onDocSuccess).catch(onError);
}

export default {
  name: "document-renderer",
  props: ["additionaldata", "langCode", "countryCode", "contentType", "slug"],
  data() {
    return {
      id: null,
      data: null,
      component: null,
    };
  },
  mounted() {
    const docId = getDocId(this.$vnode);
    const type = getType(this.$vnode);
    if (!docId) {
      return;
    }
    loadData.call(this, docId, type);
  },
};
</script>

<style>
</style>
